import React, {Component} from "react";
import './ImportDataDialog.css';
import Dialog from "@mui/material/Dialog";
import { parse } from 'papaparse';

//Components

class ImportDataDialog extends Component {

    state = {
        items:[],
        hovered:false
    }

    onDragOverHandler = (e) => {
        e.preventDefault();
    }

    onDropHandler = (e) => {
        e.preventDefault();
        Array.from(e.dataTransfer.files)
            .forEach(async file => {
                const text = await file.text();
                const result = parse(text, {header:true,encoding: "UTF-8"});
                this.props.processMultipleItems(result.data);
            });
        this.setState({hovered:false});
    }

    onDragEnterHandler = () => {
        this.setState({hovered:true});
    }

    onDragLeaveHandler = () => {
        this.setState({hovered:false});
    }

    render() {

        let style = null;

        if(this.state.hovered) {
            style = {
                backgroundColor:'gray'
            }
        }else {
            style = {
                backgroundColor:'white'
            }
        }

        return (
            <Dialog open={this.props.open} onClose={this.props.close} className='import-data-dialog'>
                <div
                    onDragOver={this.onDragOverHandler}
                    onDrop={this.onDropHandler}
                    onDragEnter={this.onDragEnterHandler}
                    onDragLeave={this.onDragLeaveHandler}
                    className='surrounder'
                    style={style}
                >
                    <h3>Ziehen Sie eine .csv-Datei mit den Daten in das Feld.</h3>
                    <p className="import-info">
                        Beachten Sie dabei unbedingt die korrekte Formatierung der Datei.
                    </p>
                </div>
            </Dialog>
        )
    }
}

export default ImportDataDialog;