import React, {Component} from 'react';
import './AccountingManagementSystem.css';

//Components
import EmailPanel from "../CommonComponents/EmailPanel/EmailPanel";
import ArticleInformation from "../CommonComponents/ArticleInformation/ArticleInformation";
import UserMenu from "../CommonComponents/UserMenu/UserMenu";
import AccountingToolbar from "./AccountingToolbar/AccountingToolbar";
import LoadingScreen from "../CommonComponents/LoadingScreen/LoadingScreen";
import AccountingActionsMenu from "./AccountingActionsMenu/AccountingActionsMenu";
import AccountingActions from "./AccountingActions/AccountingActions";

class AccountingManagementSystem extends Component {

    componentDidMount() {
        let loadRequest = {
            loadAccountPlan:!this.props.accountPlan,
            loadArticleGroups:!this.props.articleGroups,
            loadArticles:!this.props.articles,
            loadAttributes:!this.props.attributes,
            loadSentEmails:!this.props.sentEmails,
            loadCustomers:!this.props.customers,
            loadLocations:!this.props.locations,
            loadSuppliers:!this.props.suppliers,
            loadUsers:!this.props.users
        }
        this.props.loadData(loadRequest);
    }

    render() {

        let content;

        if(
            this.props.accountPlan &&
            this.props.articleGroups &&
            this.props.articles &&
            this.props.attributes &&
            this.props.company &&
            this.props.customers &&
            this.props.locations &&
            this.props.sentEmails &&
            this.props.suppliers &&
            this.props.users &&
            this.props.user
        ) {
            content = <div className='surrounder'>
                <AccountingToolbar
                    user={this.props.user}
                    company={this.props.company}
                />
                <div className='content'>
                    <UserMenu
                        //Data
                        user={this.props.user}
                        logout={this.props.logout}
                    />
                    <AccountingActionsMenu/>
                    <AccountingActions
                        //Common
                        user={this.props.user}
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        round={this.props.round}
                        logout={this.props.logout}
                        setLoading={this.props.setLoading}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}

                        //Transactions
                        addTransactionToHistory={this.props.addTransactionToHistory}

                        //Articles
                        articleInformation={this.props.articleInformation}
                        displayArticleInformation={this.props.displayArticleInformation}
                        getArticleInformation={this.props.getArticleInformation}
                        articles={this.props.articles}
                        addNewArticle={this.props.addNewArticle}
                        addMultipleArticles={this.props.addMultipleArticles}
                        updateArticle={this.props.updateArticle}
                        deleteArticle={this.props.deleteArticle}
                        updateMinimumStock={this.props.updateMinimumStock}

                        //Locations
                        locations={this.props.locations}
                        addNewLocation={this.props.addNewLocation}
                        addMultipleLocations={this.props.addMultipleLocations}
                        updateLocation={this.props.updateLocation}
                        deleteLocation={this.props.deleteLocation}

                        //Customers
                        customers={this.props.customers}
                        addNewCustomer={this.props.addNewCustomer}
                        addMultipleCustomers={this.props.addMultipleCustomers}
                        updateCustomer={this.props.updateCustomer}
                        deleteCustomer={this.props.deleteCustomer}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        addNewSupplier={this.props.addNewSupplier}
                        addMultipleSuppliers={this.props.addMultipleSuppliers}
                        updateSupplier={this.props.updateSupplier}
                        deleteSupplier={this.props.deleteSupplier}

                        //Files
                        saveFile={this.props.saveFile}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}

                        //Attributes
                        attributes={this.props.attributes}
                        addNewAttribute={this.props.addNewAttribute}
                        addMultipleAttributes={this.props.addMultipleAttributes}
                        updateAttribute={this.props.updateAttribute}
                        deleteAttribute={this.props.deleteAttribute}

                        //Users
                        users={this.props.users}
                        addNewUser={this.props.addNewUser}
                        updateUser={this.props.updateUser}
                        deleteUser={this.props.deleteUser}
                        changePassword={this.props.changePassword}
                        changeAssignedLocation={this.props.changeAssignedLocation}
                        changeOwnPassword={this.props.changeOwnPassword}

                        //CustomerOrders
                        customerOrders={this.props.customerOrders}
                        addCustomerOrderToList={this.props.addCustomerOrderToList}
                        removeCustomerOrderFromList={this.props.removeCustomerOrderFromList}

                        //SupplierOrders
                        supplierOrders={this.props.supplierOrders}
                        addSupplierOrderToList={this.props.addSupplierOrderToList}
                        removeSupplierOrderFromList={this.props.removeSupplierOrderFromList}

                        //PartsLists
                        partsLists={this.props.partsLists}
                        addPartsListToList={this.props.addPartsListToList}
                        removePartsListFromList={this.props.removePartsListFromList}

                        //Reservations
                        reservations={this.props.reservations}
                        addReservationToList={this.props.addReservationToList}
                        removeReservationFromList={this.props.removeReservationFromList}

                        //Article Groups
                        articleGroups={this.props.articleGroups}
                        addNewArticleGroup={this.props.addNewArticleGroup}
                        updateArticleGroup={this.props.updateArticleGroup}
                        deleteArticleGroup={this.props.deleteArticleGroup}

                        //Account Plan
                        accountPlan={this.props.accountPlan}
                        saveAccountPlan={this.props.saveAccountPlan}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}

                        //Accounts
                        accounts={this.props.accounts}
                    />
                    <EmailPanel
                        showMessage={this.props.showMessage}
                        formatString={this.props.formatString}
                        formatDate={this.props.formatDate}
                        setLoading={this.props.setLoading}

                        //User
                        user={this.props.user}

                        //Email
                        addEmailAttachment={this.props.addEmailAttachment}
                        emailAttachments={this.props.emailAttachments}
                        removeEmailAttachment={this.props.removeEmailAttachment}
                        clearEmailAttachments={this.props.clearEmailAttachments}

                        //Sent Emails
                        sentEmails={this.props.sentEmails}
                        addSentEmailToList={this.props.addSentEmailToList}
                        removeSentEmailFromList={this.props.removeSentEmailFromList}

                        //Company
                        company={this.props.company}

                        //Customers
                        customers={this.props.customers}
                    />
                    <ArticleInformation
                        articleInformation={this.props.articleInformation}
                        setLoading={this.props.setLoading}
                    />
                </div>
            </div>
        } else{
            content = <LoadingScreen/>
        }
        return (
            <div className='accounting-management-system'>
                {content}
            </div>
        )
    }
}

export default AccountingManagementSystem;