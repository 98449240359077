import React, {Component} from 'react';
import './ModuleOverview.css';

//Components
import WarehouseManagementSystem from "./WarehouseManagementSystem/WarehouseManagementSystem";
import wm_logo from "./../../../images/wm_logo.png";
import am_logo from "./../../../images/am_logo.svg";
import dm_logo from "./../../../images/dm_logo.svg";
import blocked from "./../../../images/ban-solid.svg";
import {NavLink, Route} from "react-router-dom";
import AccountingManagementSystem from "./AccountingManagementSystem/AccountingManagementSystem";
import DocumentManagementSystem from "./DocumentManagementSystem/DocumentManagementSystem";
import CostManagerSystem from "./CostManagerSystem/CostManagerSystem";
import { Tooltip } from 'react-tooltip'

class ModuleOverview extends Component {
    render() {
        return (
            <div className='module-overview'>
                <Tooltip id="module-overview-tooltip"/>
                <Route exact path='/'>
                    <menu className='modules'>
                        {this.props.company && this.props.company.warehouseManager ? <NavLink data-tooltip-id="module-overview-tooltip" data-tooltip-place="top" data-tooltip-content='Warehouse Manager 4.0' className='module' to='/warehouse-manager'><img alt='Warehouse Manager 4.0' src={wm_logo}/><h2 className='module-name'>Warehouse Manager 4.0</h2></NavLink> :
                            <div className='deactivated-module'><img src={blocked} alt="Geblocktes Modul"/><h2 className='module-name'>Warehouse Manager 4.0</h2></div>}
                        {this.props.company && this.props.company.accountingManager ? <NavLink data-tooltip-id="module-overview-tooltip" data-tooltip-place="top" data-tooltip-content='Accounting Manager 4.0' className='module' to='/accounting-manager'><img alt='Accounting Manager 4.0' src={am_logo}/><h2 className='module-name'>Accounting Manager 4.0</h2></NavLink> :
                            <div className='deactivated-module'><img src={blocked} alt="Geblocktes Modul"/><h2 className='module-name'>Accounting Manager 4.0</h2></div>}
                        {this.props.company && this.props.company.documentManager ? <NavLink data-tooltip-id="module-overview-tooltip" data-tooltip-place="top" data-tooltip-content='Document Manager 4.0' className='module' to='/document-manager'><img alt='Document Manager 4.0' src={dm_logo}/><h2 className='module-name'>Document Manager 4.0</h2></NavLink> :
                            <div className='deactivated-module'><img src={blocked} alt="Geblocktes Modul"/><h2 className='module-name'>Document Manager 4.0</h2></div>}
                    </menu>
                </Route>
                <Route path='/warehouse-manager'>
                    <WarehouseManagementSystem
                        //Common
                        loadData={this.props.loadData}
                        showMessage={this.props.showMessage}
                        displayArticleInformation={this.props.displayArticleInformation}
                        analyzes={this.props.analyzes}
                        countries={this.props.countries}
                        round={this.props.round}
                        formatString={this.props.formatString}
                        formatDate={this.props.formatDate}
                        generateDocument={this.props.generateDocument}
                        setLoading={this.props.setLoading}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}

                        user={this.props.user}
                        changeUsername={this.props.changeUsername}
                        changePassword={this.props.changePassword}
                        changeAssignedLocation={this.props.changeAssignedLocation}
                        changeOwnPassword={this.props.changeOwnPassword}
                        updateUserInformation={this.props.updateUserInformation}
                        updateDefaultCustomerOrderFilter={this.props.updateDefaultCustomerOrderFilter}
                        updateDefaultSupplierOrderFilter={this.props.updateDefaultSupplierOrderFilter}
                        logout={this.props.logout}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}

                        //Articles
                        articleInformation={this.props.articleInformation}
                        articles={this.props.articles}
                        loadArticles={this.props.loadArticles}
                        addNewArticle={this.props.addNewArticle}
                        addArticleToList={this.props.addArticleToList}
                        addMultipleArticles={this.props.addMultipleArticles}
                        updateArticle={this.props.updateArticle}
                        deleteArticle={this.props.deleteArticle}
                        getArticleInformation={this.props.getArticleInformation}
                        updateMinimumStock={this.props.updateMinimumStock}

                        //MessageTemplates
                        messageTemplates={this.props.messageTemplates}
                        loadMessageTemplates={this.props.loadMessageTemplates}
                        addNewMessageTemplate={this.props.addNewMessageTemplate}
                        updateMessageTemplate={this.props.updateMessageTemplate}
                        addMessageTemplateToList={this.props.addMessageTemplateToList}
                        deleteMessageTemplate={this.props.deleteMessageTemplate}

                        //Locations
                        locations={this.props.locations}
                        loadLocations={this.props.loadLocations}
                        addNewLocation={this.props.addNewLocation}
                        addMultipleLocations={this.props.addMultipleLocations}
                        updateLocation={this.props.updateLocation}
                        deleteLocation={this.props.deleteLocation}

                        //Customers
                        customers={this.props.customers}
                        loadCustomers={this.props.loadCustomers}
                        addNewCustomer={this.props.addNewCustomer}
                        addMultipleCustomers={this.props.addMultipleCustomers}
                        updateCustomer={this.props.updateCustomer}
                        deleteCustomer={this.props.deleteCustomer}
                        addCustomerToList={this.props.addCustomerToList}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        loadSuppliers={this.props.loadSuppliers}
                        addNewSupplier={this.props.addNewSupplier}
                        addMultipleSuppliers={this.props.addMultipleSuppliers}
                        updateSupplier={this.props.updateSupplier}
                        deleteSupplier={this.props.deleteSupplier}
                        addSupplierToList={this.props.addSupplierToList}

                        //Attributes
                        attributes={this.props.attributes}
                        loadAttributes={this.props.loadAttributes}
                        addNewAttribute={this.props.addNewAttribute}
                        addMultipleAttributes={this.props.addMultipleAttributes}
                        updateAttribute={this.props.updateAttribute}
                        deleteAttribute={this.props.deleteAttribute}

                        //Users
                        users={this.props.users}
                        loadUsers={this.props.loadUsers}
                        addNewUser={this.props.addNewUser}
                        updateUser={this.props.updateUser}
                        deleteUser={this.props.deleteUser}

                        //CustomerOrders
                        customerOrders={this.props.customerOrders}
                        loadCustomerOrders={this.props.loadCustomerOrders}
                        addNewCustomerOrder={this.props.addNewCustomerOrder}
                        updateCustomerOrder={this.props.updateCustomerOrder}
                        deleteCustomerOrder={this.props.deleteCustomerOrder}
                        removeCustomerOrderFromList={this.props.removeCustomerOrderFromList}
                        addCustomerOrderToList={this.props.addCustomerOrderToList}

                        //SupplierOrders
                        supplierOrders={this.props.supplierOrders}
                        loadSupplierOrders={this.props.loadSupplierOrders}
                        addSupplierOrderToList={this.props.addSupplierOrderToList}
                        removeSupplierOrderFromList={this.props.removeSupplierOrderFromList}

                        //PartsLists
                        partsLists={this.props.partsLists}
                        loadPartsLists={this.props.loadPartsLists}
                        addPartsListToList={this.props.addPartsListToList}
                        removePartsListFromList={this.props.removePartsListFromList}

                        //Reservations
                        reservations={this.props.reservations}
                        loadReservations={this.props.loadReservations}
                        addReservationToList={this.props.addReservationToList}
                        removeReservationFromList={this.props.removeReservationFromList}

                        //Transactions
                        transactions={this.props.transactions}
                        loadTransactions={this.props.loadTransactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}
                        removeTransactionFromHistory={this.props.removeTransactionFromHistory}
                        bookCustomerOrderInventoryTransactions={this.props.bookCustomerOrderInventoryTransactions}
                        bookSupplierOrderInventoryTransactions={this.props.bookSupplierOrderInventoryTransactions}

                        //Article Groups
                        articleGroups={this.props.articleGroups}
                        loadArticleGroups={this.props.loadArticleGroups}
                        addNewArticleGroup={this.props.addNewArticleGroup}
                        updateArticleGroup={this.props.updateArticleGroup}
                        deleteArticleGroup={this.props.deleteArticleGroup}

                        //Account Plan
                        accountPlan={this.props.accountPlan}
                        loadAccountPlan={this.props.loadAccountPlan}
                        saveAccountPlan={this.props.saveAccountPlan}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}

                        //Accounts
                        accounts={this.props.accounts}

                        //Files
                        saveFile={this.props.saveFile}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}

                        //Factory Certificates
                        loadFactoryCertificates={this.props.loadFactoryCertificates}
                        factoryCertificates={this.props.factoryCertificates}
                        addFactoryCertificateToList={this.props.addFactoryCertificateToList}
                        removeFactoryCertificateFromList={this.props.removeFactoryCertificateFromList}

                        //Email
                        addEmailAttachment={this.props.addEmailAttachment}
                        emailAttachments={this.props.emailAttachments}
                        removeEmailAttachment={this.props.removeEmailAttachment}
                        clearEmailAttachments={this.props.clearEmailAttachments}
                        emailReferencedOrder={this.props.emailReferencedOrder}
                        setEmailReferencedOrder={this.props.setEmailReferencedOrder}

                        //Sent Emails
                        loadSentEmails={this.props.loadSentEmails}
                        sentEmails={this.props.sentEmails}
                        addSentEmailToList={this.props.addSentEmailToList}
                        removeSentEmailFromList={this.props.removeSentEmailFromList}

                        //Filters
                        filters={this.props.filters}
                        addFilterToList={this.props.addFilterToList}
                        removeFilterFromList={this.props.removeFilterFromList}

                        // Batches
                        batches={this.props.batches}
                        addBatchToList={this.props.addBatchToList}
                        updateBatchInList={this.props.updateBatchInList}
                        removeBatchFromList={this.props.removeBatchFromList}
                        batchInformation={this.props.batchInformation}
                        displayBatchInformation={this.props.displayBatchInformation}
                    />
                </Route>
                <Route path='/accounting-manager'>
                    <AccountingManagementSystem
                        //Common
                        loadData={this.props.loadData}
                        showMessage={this.props.showMessage}
                        displayArticleInformation={this.props.displayArticleInformation}
                        getArticleInformation={this.props.getArticleInformation}
                        analyzes={this.props.analyzes}
                        countries={this.props.countries}
                        round={this.props.round}
                        formatString={this.props.formatString}
                        formatDate={this.props.formatDate}
                        setLoading={this.props.setLoading}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}

                        user={this.props.user}
                        changeUsername={this.props.changeUsername}
                        changePassword={this.props.changePassword}
                        changeAssignedLocation={this.props.changeAssignedLocation}
                        changeOwnPassword={this.props.changeOwnPassword}
                        updateUserInformation={this.props.updateUserInformation}
                        updateDefaultCustomerOrderFilter={this.props.updateDefaultCustomerOrderFilter}
                        updateDefaultSupplierOrderFilter={this.props.updateDefaultSupplierOrderFilter}
                        logout={this.props.logout}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}

                        //Articles
                        articleInformation={this.props.articleInformation}
                        articles={this.props.articles}
                        loadArticles={this.props.loadArticles}
                        addNewArticle={this.props.addNewArticle}
                        addMultipleArticles={this.props.addMultipleArticles}
                        updateArticle={this.props.updateArticle}
                        deleteArticle={this.props.deleteArticle}
                        updateMinimumStock={this.props.updateMinimumStock}

                        //MessageTemplates
                        messageTemplates={this.props.messageTemplates}
                        loadMessageTemplates={this.props.loadMessageTemplates}
                        addNewMessageTemplate={this.props.addNewMessageTemplate}
                        updateMessageTemplate={this.props.updateMessageTemplate}
                        addMessageTemplateToList={this.props.addMessageTemplateToList}
                        deleteMessageTemplate={this.props.deleteMessageTemplate}

                        //Locations
                        locations={this.props.locations}
                        loadLocations={this.props.loadLocations}
                        addNewLocation={this.props.addNewLocation}
                        addMultipleLocations={this.props.addMultipleLocations}
                        updateLocation={this.props.updateLocation}
                        deleteLocation={this.props.deleteLocation}

                        //Customers
                        customers={this.props.customers}
                        loadCustomers={this.props.loadCustomers}
                        addNewCustomer={this.props.addNewCustomer}
                        addMultipleCustomers={this.props.addMultipleCustomers}
                        updateCustomer={this.props.updateCustomer}
                        deleteCustomer={this.props.deleteCustomer}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        loadSuppliers={this.props.loadSuppliers}
                        addNewSupplier={this.props.addNewSupplier}
                        addMultipleSuppliers={this.props.addMultipleSuppliers}
                        updateSupplier={this.props.updateSupplier}
                        deleteSupplier={this.props.deleteSupplier}

                        //Attributes
                        attributes={this.props.attributes}
                        loadAttributes={this.props.loadAttributes}
                        addNewAttribute={this.props.addNewAttribute}
                        addMultipleAttributes={this.props.addMultipleAttributes}
                        updateAttribute={this.props.updateAttribute}
                        deleteAttribute={this.props.deleteAttribute}

                        //Users
                        users={this.props.users}
                        loadUsers={this.props.loadUsers}
                        addNewUser={this.props.addNewUser}
                        updateUser={this.props.updateUser}
                        deleteUser={this.props.deleteUser}

                        //CustomerOrders
                        customerOrders={this.props.customerOrders}
                        loadCustomerOrders={this.props.loadCustomerOrders}
                        addCustomerOrderToList={this.props.addCustomerOrderToList}
                        removeCustomerOrderFromList={this.props.removeCustomerOrderFromList}

                        //SupplierOrders
                        supplierOrders={this.props.supplierOrders}
                        loadSupplierOrders={this.props.loadSupplierOrders}
                        addSupplierOrderToList={this.props.addSupplierOrderToList}
                        removeSupplierOrderFromList={this.props.removeSupplierOrderFromList}

                        //PartsLists
                        partsLists={this.props.partsLists}
                        loadPartsLists={this.props.loadPartsLists}
                        addPartsListToList={this.props.addPartsListToList}
                        removePartsListFromList={this.props.removePartsListFromList}

                        //Reservations
                        reservations={this.props.reservations}
                        loadReservations={this.props.loadReservations}
                        addReservationToList={this.props.addReservationToList}
                        removeReservationFromList={this.props.removeReservationFromList}

                        //Transactions
                        transactions={this.props.transactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}

                        //Article Groups
                        articleGroups={this.props.articleGroups}
                        loadArticleGroups={this.props.loadArticleGroups}
                        addNewArticleGroup={this.props.addNewArticleGroup}
                        updateArticleGroup={this.props.updateArticleGroup}
                        deleteArticleGroup={this.props.deleteArticleGroup}

                        //Account Plan
                        accountPlan={this.props.accountPlan}
                        loadAccountPlan={this.props.loadAccountPlan}
                        saveAccountPlan={this.props.saveAccountPlan}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}

                        //Accounts
                        accounts={this.props.accounts}

                        //Email
                        addEmailAttachment={this.props.addEmailAttachment}
                        emailAttachments={this.props.emailAttachments}
                        removeEmailAttachment={this.props.removeEmailAttachment}
                        clearEmailAttachments={this.props.clearEmailAttachments}
                        emailReferencedOrder={this.props.emailReferencedOrder}
                        setEmailReferencedOrder={this.props.setEmailReferencedOrder}

                        //Sent Emails
                        loadSentEmails={this.props.loadSentEmails}
                        sentEmails={this.props.sentEmails}
                        addSentEmailToList={this.props.addSentEmailToList}
                        removeSentEmailFromList={this.props.removeSentEmailFromList}

                        //Files
                        saveFile={this.props.saveFile}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}

                        //Filters
                        filters={this.props.filters}
                        addFilterToList={this.props.addFilterToList}
                        removeFilterFromList={this.props.removeFilterFromList}
                    />
                </Route>
                <Route path='/document-manager'>
                    <DocumentManagementSystem
                        //Common
                        loadData={this.props.loadData}
                        showMessage={this.props.showMessage}
                        displayArticleInformation={this.props.displayArticleInformation}
                        getArticleInformation={this.props.getArticleInformation}
                        analyzes={this.props.analyzes}
                        countries={this.props.countries}
                        round={this.props.round}
                        formatString={this.props.formatString}
                        formatDate={this.props.formatDate}
                        generateDocument={this.props.generateDocument}
                        generateDeliveryBill={this.props.generateDeliveryBill}
                        generateDPDShippingLabel={this.props.generateDPDShippingLabel}
                        generateDHLShippingLabel={this.props.generateDHLShippingLabel}
                        setLoading={this.props.setLoading}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}

                        user={this.props.user}
                        changeUsername={this.props.changeUsername}
                        changePassword={this.props.changePassword}
                        changeAssignedLocation={this.props.changeAssignedLocation}
                        changeOwnPassword={this.props.changeOwnPassword}
                        updateUserInformation={this.props.updateUserInformation}
                        updateDefaultCustomerOrderFilter={this.props.updateDefaultCustomerOrderFilter}
                        updateDefaultSupplierOrderFilter={this.props.updateDefaultSupplierOrderFilter}
                        logout={this.props.logout}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}

                        //Articles
                        articleInformation={this.props.articleInformation}
                        articles={this.props.articles}
                        loadArticles={this.props.loadArticles}
                        addNewArticle={this.props.addNewArticle}
                        addArticleToList={this.props.addArticleToList}
                        addMultipleArticles={this.props.addMultipleArticles}
                        updateArticle={this.props.updateArticle}
                        deleteArticle={this.props.deleteArticle}
                        updateMinimumStock={this.props.updateMinimumStock}

                        //MessageTemplates
                        messageTemplates={this.props.messageTemplates}
                        loadMessageTemplates={this.props.loadMessageTemplates}
                        addNewMessageTemplate={this.props.addNewMessageTemplate}
                        updateMessageTemplate={this.props.updateMessageTemplate}
                        addMessageTemplateToList={this.props.addMessageTemplateToList}
                        deleteMessageTemplate={this.props.deleteMessageTemplate}

                        //Locations
                        locations={this.props.locations}
                        loadLocations={this.props.loadLocations}
                        addNewLocation={this.props.addNewLocation}
                        addMultipleLocations={this.props.addMultipleLocations}
                        updateLocation={this.props.updateLocation}
                        deleteLocation={this.props.deleteLocation}

                        //Customers
                        customers={this.props.customers}
                        loadCustomers={this.props.loadCustomers}
                        addNewCustomer={this.props.addNewCustomer}
                        addMultipleCustomers={this.props.addMultipleCustomers}
                        updateCustomer={this.props.updateCustomer}
                        deleteCustomer={this.props.deleteCustomer}
                        addCustomerToList={this.props.addCustomerToList}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        loadSuppliers={this.props.loadSuppliers}
                        addNewSupplier={this.props.addNewSupplier}
                        addMultipleSuppliers={this.props.addMultipleSuppliers}
                        updateSupplier={this.props.updateSupplier}
                        deleteSupplier={this.props.deleteSupplier}
                        addSupplierToList={this.props.addSupplierToList}

                        //Attributes
                        attributes={this.props.attributes}
                        loadAttributes={this.props.loadAttributes}
                        addNewAttribute={this.props.addNewAttribute}
                        addMultipleAttributes={this.props.addMultipleAttributes}
                        updateAttribute={this.props.updateAttribute}
                        deleteAttribute={this.props.deleteAttribute}

                        //Users
                        users={this.props.users}
                        loadUsers={this.props.loadUsers}
                        addNewUser={this.props.addNewUser}
                        updateUser={this.props.updateUser}
                        deleteUser={this.props.deleteUser}

                        //CustomerOrders
                        customerOrders={this.props.customerOrders}
                        addNewCustomerOrder={this.props.addNewCustomerOrder}
                        updateCustomerOrder={this.props.updateCustomerOrder}
                        deleteCustomerOrder={this.props.deleteCustomerOrder}
                        loadCustomerOrders={this.props.loadCustomerOrders}
                        addCustomerOrderToList={this.props.addCustomerOrderToList}
                        removeCustomerOrderFromList={this.props.removeCustomerOrderFromList}

                        //SupplierOrders
                        supplierOrders={this.props.supplierOrders}
                        loadSupplierOrders={this.props.loadSupplierOrders}
                        addSupplierOrderToList={this.props.addSupplierOrderToList}
                        removeSupplierOrderFromList={this.props.removeSupplierOrderFromList}

                        //PartsLists
                        partsLists={this.props.partsLists}
                        loadPartsLists={this.props.loadPartsLists}
                        addPartsListToList={this.props.addPartsListToList}
                        removePartsListFromList={this.props.removePartsListFromList}

                        //Reservations
                        reservations={this.props.reservations}
                        loadReservations={this.props.loadReservations}
                        addReservationToList={this.props.addReservationToList}
                        removeReservationFromList={this.props.removeReservationFromList}

                        //Transactions
                        transactions={this.props.transactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}
                        bookCustomerOrderInventoryTransactions={this.props.bookCustomerOrderInventoryTransactions}
                        bookSupplierOrderInventoryTransactions={this.props.bookSupplierOrderInventoryTransactions}

                        //Article Groups
                        articleGroups={this.props.articleGroups}
                        loadArticleGroups={this.props.loadArticleGroups}
                        addNewArticleGroup={this.props.addNewArticleGroup}
                        updateArticleGroup={this.props.updateArticleGroup}
                        deleteArticleGroup={this.props.deleteArticleGroup}

                        //Account Plan
                        accountPlan={this.props.accountPlan}
                        loadAccountPlan={this.props.loadAccountPlan}
                        saveAccountPlan={this.props.saveAccountPlan}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}

                        //Accounts
                        accounts={this.props.accounts}

                        //Directory Structures
                        loadRootDirectory={this.props.loadRootDirectory}
                        updateMultipleFilesOnList={this.props.updateMultipleFilesOnList}
                        rootDirectory={this.props.rootDirectory}

                        //Files
                        saveFile={this.props.saveFile}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}

                        //Factory Certificates
                        loadFactoryCertificates={this.props.loadFactoryCertificates}
                        factoryCertificates={this.props.factoryCertificates}
                        addFactoryCertificateToList={this.props.addFactoryCertificateToList}
                        removeFactoryCertificateFromList={this.props.removeFactoryCertificateFromList}

                        //Email
                        addEmailAttachment={this.props.addEmailAttachment}
                        emailAttachments={this.props.emailAttachments}
                        removeEmailAttachment={this.props.removeEmailAttachment}
                        clearEmailAttachments={this.props.clearEmailAttachments}
                        emailReferencedOrder={this.props.emailReferencedOrder}
                        setEmailReferencedOrder={this.props.setEmailReferencedOrder}

                        //Sent Emails
                        loadSentEmails={this.props.loadSentEmails}
                        sentEmails={this.props.sentEmails}
                        addSentEmailToList={this.props.addSentEmailToList}
                        removeSentEmailFromList={this.props.removeSentEmailFromList}

                        //Filters
                        filters={this.props.filters}
                        addFilterToList={this.props.addFilterToList}
                        removeFilterFromList={this.props.removeFilterFromList}

                        // Batches
                        batches={this.props.batches}
                        addBatchToList={this.props.addBatchToList}
                        updateBatchInList={this.props.updateBatchInList}
                        removeBatchFromList={this.props.removeBatchFromList}
                        batchInformation={this.props.batchInformation}
                        displayBatchInformation={this.props.displayBatchInformation}
                    />
                </Route>
            </div>
        )
    }
}

export default ModuleOverview;