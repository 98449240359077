import React, {Component} from "react";
import './Inventory.css';

//Components
import {Route} from "react-router-dom";
import ArticleMovement from "./ArticleMovement/ArticleMovement";
import InventoryContent from "./InventoryContent/InventoryContent";
import WarehouseActions from "../WarehouseActions";
import StockTaking from "./StockTaking/StockTaking";
import Batches from "./Batches/Batches";

class Inventory extends Component {
    render() {
        return (
            <div className='inventory'>
                <Route exact path='/warehouse-manager/inventory'>
                    <ArticleMovement
                        showMessage={this.props.showMessage}
                        items={this.props.customerOrders}
                        displayFile={this.props.displayFile}
                        removeItemFromList={this.props.removeCustomerOrderFromList}
                        addItemToList={this.props.addCustomerOrderToList}
                        user={this.props.user}
                        getArticleInformation={this.props.getArticleInformation}
                        displayArticleInformation={this.props.displayArticleInformation}
                        setLoading={this.props.setLoading}
                        addArticleToList={this.props.addArticleToList}
                        articleInformation={this.props.articleInformation}
                        logout={this.props.logout}
                        updateMinimumStock={this.props.updateMinimumStock}

                        generateDocument={this.props.generateDocument}
                        generateDeliveryBill={this.props.generateDeliveryBill}
                        generateDPDShippingLabel={this.props.generateDPDShippingLabel}
                        generateDHLShippingLabel={this.props.generateDHLShippingLabel}
                        bookCustomerOrderInventoryTransactions={this.props.bookCustomerOrderInventoryTransactions}
                        round={this.props.round}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}
                        attributes={this.props.attributes}
                        customers={this.props.customers}
                        articles={this.props.articles}
                        company={this.props.company}
                        articleGroups={this.props.articleGroups}
                        locations={this.props.locations}
                        suppliers={this.props.suppliers}
                        addCustomerToList={this.props.addCustomerToList}
                        addSupplierToList={this.props.addSupplierToList}

                        //Email
                        sendEmail={this.props.sendEmail}

                        //Transactions
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}
                        removeTransactionFromHistory={this.props.removeTransactionFromHistory}
                        transactions={this.props.transactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}

                        // Batches
                        batches={this.props.batches}
                        addBatchToList={this.props.addBatchToList}
                        updateBatchInList={this.props.updateBatchInList}
                        removeBatchFromList={this.props.removeBatchFromList}
                        batchInformation={this.props.batchInformation}
                        displayBatchInformation={this.props.displayBatchInformation}
                    />
                </Route>
                <Route exact path='/warehouse-manager/inventory/batches'>
                    <Batches
                        showMessage={this.props.showMessage}
                        items={this.props.customerOrders}
                        displayFile={this.props.displayFile}
                        removeItemFromList={this.props.removeCustomerOrderFromList}
                        addItemToList={this.props.addCustomerOrderToList}
                        user={this.props.user}
                        getArticleInformation={this.props.getArticleInformation}
                        displayArticleInformation={this.props.displayArticleInformation}
                        setLoading={this.props.setLoading}
                        addArticleToList={this.props.addArticleToList}
                        articleInformation={this.props.articleInformation}
                        logout={this.props.logout}

                        generateDocument={this.props.generateDocument}
                        generateDeliveryBill={this.props.generateDeliveryBill}
                        generateDPDShippingLabel={this.props.generateDPDShippingLabel}
                        generateDHLShippingLabel={this.props.generateDHLShippingLabel}
                        bookCustomerOrderInventoryTransactions={this.props.bookCustomerOrderInventoryTransactions}
                        round={this.props.round}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}
                        attributes={this.props.attributes}
                        customers={this.props.customers}
                        articles={this.props.articles}
                        company={this.props.company}
                        articleGroups={this.props.articleGroups}
                        locations={this.props.locations}
                        suppliers={this.props.suppliers}
                        addCustomerToList={this.props.addCustomerToList}
                        addSupplierToList={this.props.addSupplierToList}

                        //Email
                        sendEmail={this.props.sendEmail}

                        //Transactions
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}
                        removeTransactionFromHistory={this.props.removeTransactionFromHistory}
                        transactions={this.props.transactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}

                        // Batches
                        batches={this.props.batches}
                        addBatchToList={this.props.addBatchToList}
                        updateBatchInList={this.props.updateBatchInList}
                        removeBatchFromList={this.props.removeBatchFromList}
                        batchInformation={this.props.batchInformation}
                        displayBatchInformation={this.props.displayBatchInformation}
                    />
                </Route>
                <Route exact path='/warehouse-manager/inventory/stock-taking'>
                    <StockTaking
                        showMessage={this.props.showMessage}
                        items={this.props.customerOrders}
                        displayFile={this.props.displayFile}
                        removeItemFromList={this.props.removeCustomerOrderFromList}
                        addItemToList={this.props.addCustomerOrderToList}
                        user={this.props.user}
                        getArticleInformation={this.props.getArticleInformation}
                        displayArticleInformation={this.props.displayArticleInformation}
                        setLoading={this.props.setLoading}
                        addArticleToList={this.props.addArticleToList}
                        articleInformation={this.props.articleInformation}
                        logout={this.props.logout}

                        generateDocument={this.props.generateDocument}
                        generateDeliveryBill={this.props.generateDeliveryBill}
                        generateDPDShippingLabel={this.props.generateDPDShippingLabel}
                        generateDHLShippingLabel={this.props.generateDHLShippingLabel}
                        bookCustomerOrderInventoryTransactions={this.props.bookCustomerOrderInventoryTransactions}
                        round={this.props.round}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}
                        attributes={this.props.attributes}
                        customers={this.props.customers}
                        articles={this.props.articles}
                        company={this.props.company}
                        articleGroups={this.props.articleGroups}
                        locations={this.props.locations}
                        suppliers={this.props.suppliers}
                        addCustomerToList={this.props.addCustomerToList}
                        addSupplierToList={this.props.addSupplierToList}

                        //Email
                        sendEmail={this.props.sendEmail}

                        //Transactions
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}
                        removeTransactionFromHistory={this.props.removeTransactionFromHistory}
                        transactions={this.props.transactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}

                        // Batches
                        batches={this.props.batches}
                        addBatchToList={this.props.addBatchToList}
                        updateBatchInList={this.props.updateBatchInList}
                        removeBatchFromList={this.props.removeBatchFromList}
                        batchInformation={this.props.batchInformation}
                        displayBatchInformation={this.props.displayBatchInformation}
                    />
                </Route>
                <Route exact path='/warehouse-manager/inventory/parts-lists'>
                    <InventoryContent
                        showMessage={this.props.showMessage}
                        removeItemFromList={this.props.removePartsListFromList}
                        addItemToList={this.props.addPartsListToList}
                        displayFile={this.props.displayFile}
                        kind={4}
                        articles={this.props.articles}
                        customers={this.props.customers}
                        company={this.props.company}
                        setLoading={this.props.setLoading}
                        addArticleToList={this.props.addArticleToList}
                        articleInformation={this.props.articleInformation}
                        logout={this.props.logout}

                        //Suppliers
                        suppliers={this.props.suppliers}

                        //Email
                        sendEmail={this.props.sendEmail}

                        //Attributes
                        attributes={this.props.attributes}

                        //PartsLists
                        partsLists={this.props.partsLists}
                        addPartsListToList={this.props.addPartsListToList}
                        removePartsListFromList={this.props.removePartsListFromList}

                        //Transactions
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}
                        transactions={this.props.transactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}

                        // Batches
                        batches={this.props.batches}
                        addBatchToList={this.props.addBatchToList}
                        updateBatchInList={this.props.updateBatchInList}
                        removeBatchFromList={this.props.removeBatchFromList}
                        batchInformation={this.props.batchInformation}
                        displayBatchInformation={this.props.displayBatchInformation}
                    />
                </Route>
                <Route exact path='/warehouse-manager/inventory/reservations'>
                    <InventoryContent
                        showMessage={this.props.showMessage}
                        removeItemFromList={this.props.removeReservationFromList}
                        addItemToList={this.props.addReservationToList}
                        displayFile={this.props.displayFile}
                        kind={5}
                        articles={this.props.articles}
                        customers={this.props.customers}
                        company={this.props.company}
                        setLoading={this.props.setLoading}
                        addArticleToList={this.props.addArticleToList}
                        articleInformation={this.props.articleInformation}
                        logout={this.props.logout}

                        //Suppliers
                        suppliers={this.props.suppliers}

                        //Email
                        sendEmail={this.props.sendEmail}

                        //Attributes
                        attributes={this.props.attributes}

                        //Reservations
                        reservations={this.props.reservations}
                        addReservationToList={this.props.addReservationToList}
                        removeReservationFromList={this.props.removeReservationFromList}

                        //Transactions
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}
                        transactions={this.props.transactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}

                        // Batches
                        batches={this.props.batches}
                        addBatchToList={this.props.addBatchToList}
                        updateBatchInList={this.props.updateBatchInList}
                        removeBatchFromList={this.props.removeBatchFromList}
                        batchInformation={this.props.batchInformation}
                        displayBatchInformation={this.props.displayBatchInformation}
                    />
                </Route>
            </div>
        );
    }
}

export default Inventory;