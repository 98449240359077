import React, { useState, useEffect, useRef } from 'react';
import './SearchTransactionDialog.css';
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import HelperFunctions from "../../../../../../../../reusable/HelperFunctions";
import TextField from "@mui/material/TextField";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import DialogActions from "@mui/material/DialogActions";
import TransactionItem from "../../../../../../../../reusable/TransactionItem/TransactionItem";
import api from "../../../../../../../../api";
import SecureYesCancelDialog from "../../../../../../../../reusable/SecureYesCancelDialog/SecureYesCancelDialog";
import TableRowsIcon from "@mui/icons-material/TableRows";
import * as XLSX from 'xlsx';
import {Tooltip} from "react-tooltip";

//Components

const SearchTransactionDialog = (props) => {

    const [detailedSearch,setDetailedSearch] = useState(false);
    const [searchWord,setSearchWord] = useState(null);
    const [searchArticle,setSearchArticle] = useState(null);
    const [searchCustomer,setSearchCustomer] = useState(null);
    const [searchSupplier,setSearchSupplier] = useState(null);
    const [searchBatch,setSearchBatch] = useState(null);
    const [fromDate,setFromDate] = useState(null);
    const [toDate,setToDate] = useState(null);
    const [searchedTransactions,setSearchedTransactions] = useState(null);
    const [activeTransaction,setActiveTransaction] = useState(null);
    const [deleteTransactionDialog,setDeleteTransactionDialog] = useState(false);

    const filter = createFilterOptions();

    const searchArticleOnChange = (article) => {
        setSearchArticle(article);
        props.getArticleInformation(article);
    }

    const searchCustomerOnChange = (customer) => {
        setSearchCustomer(customer);
    }

    const searchSupplierOnChange = (supplier) => {
        setSearchSupplier(supplier);
    }

    const searchBatchOnChange = (batch) => {
        setSearchBatch(batch);
    }

    const searchTransactionsOnClickHandler = () => {
        if(detailedSearch === true) {
            if(!fromDate && !toDate) {
                props.showMessage(2,"Einer detaillierten Suche muss ein 'von' und ein 'bis' Datum zugewiesen sein");
                return;
            }
        }else if(detailedSearch === false) {
            if(!searchWord) {
                props.showMessage(2,"Einer schnellen Suche muss ein Suchwort zugewiesen sein");
                return;
            }
        }
        searchTransactions();
    }
    const searchTransactions = () => {
        props.setLoading(true);
        let url = "/transactions/search";
        let searchItem = {
            searchType:detailedSearch,
            fromDate:fromDate,
            toDate:toDate
        };
        if(detailedSearch) {
            searchItem.article = searchArticle;
            searchItem.customer = searchCustomer;
            searchItem.supplier = searchSupplier;
            searchItem.batch = searchBatch;
        }else if(!detailedSearch && searchWord) {
            searchItem.searchWord = searchWord;
        }
        api.post(url,searchItem)
            .then(response => {
                console.log(response.data);
                setSearchedTransactions(response.data);
                if(response.data.length === 0) {
                    props.showMessage(1,"Es wurden keine Transaktionen mit diesen Parametern gefunden");
                }
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const deleteTransaction = (transaction) => {
        api.delete("/transactions/" + transaction.id)
            .then(response => {
                setActiveTransaction(null)
                removeTransactionFromHistory(transaction);
                props.removeTransactionFromHistory(transaction);
                props.showMessage(0,"Transaktion erfolgreich gelöscht!");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.setLoading(false);
                }
                props.setLoading(false);
            });
    }

    const removeTransactionFromHistory = (transaction) => {
        let transactions = [ ...searchedTransactions ];
        for(let i = 0; i < transactions.length; i++) {
            if (transactions[i].id === transaction.id) {
                transactions.splice(i,1)
                setSearchedTransactions(transactions);
                return;
            }
        }
    }

    const showDeleteTransactionDialog = (transaction) => {
        setActiveTransaction(transaction);
        setDeleteTransactionDialog(true);
    }

    const closeDeleteTransactionDialog = () => {
        setDeleteTransactionDialog(false);
        setActiveTransaction(null);
    }

    const close = () => {
        props.close();
        setSearchedTransactions(null);
        setFromDate(null);
        setToDate(null);
        setSearchWord(null);
        setSearchArticle(null);
        setSearchBatch(null);
        setSearchCustomer(null);
        setSearchSupplier(null);
    }

    const generateTransactionListExcel = () => {
        let formattedTransactions = [];
        for(let i = 0; i < searchedTransactions.length; i++) {
            let transaction = {
                "Identifikationsnummer":searchedTransactions[i].id,
                "Benutzer":searchedTransactions[i].user ? searchedTransactions[i].user.username : "-",
                "Artikelnummer":searchedTransactions[i].article ? searchedTransactions[i].article.nr : "-",
                "Artikelname":searchedTransactions[i].article ? searchedTransactions[i].article.name : "-",
                "Chargennummer":searchedTransactions[i].batch ? searchedTransactions[i].batch.nr : "-",
                "Anzahl":searchedTransactions[i].inventoryCount,
                "Kunde":searchedTransactions[i].assignedCustomer ? searchedTransactions[i].assignedCustomer.name : "-",
                "Lieferant":searchedTransactions[i].assignedSupplier ? searchedTransactions[i].assignedSupplier.name : "-",
                "Zeitpunkt":searchedTransactions[i].dateTime ? HelperFunctions.formatDateTime(searchedTransactions[i].dateTime) : "-"
            }
            formattedTransactions.push(transaction);
        }
        const worksheet = XLSX.utils.json_to_sheet(formattedTransactions);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Transaktionen');

        // Write the workbook to a binary string
        XLSX.writeFile(workbook, "Transaktionen.xlsx");
    }

    return (
        <Dialog scroll='body' maxWidth={"lg"} open={props.open} onClose={close} className='search-transaction-dialog'>
            <Tooltip id="search-transaction-dialog-tooltip"/>

            <SecureYesCancelDialog
                open={deleteTransactionDialog}
                close={closeDeleteTransactionDialog}
                header='Löschen'
                text='Wollen Sie die Transaktion wirklich löschen?'
                onClick={() => deleteTransaction(activeTransaction)}
                showMessage={props.showMessage}
            />

            <DialogContent>
                <CloseIcon onClick={close} className="icon close-icon"/>
                <DialogTitle>Transaktionssuche</DialogTitle>
                <FormControlLabel
                    className='field switch-label'
                    control={
                        <Switch className='switch position-display-switch' checked={detailedSearch}
                                onChange={() => setDetailedSearch(!detailedSearch)} color="primary"/>
                    }
                    label={detailedSearch ? "Detaillierte Suche" : "Schnelle Suche"}
                />

                {searchedTransactions && searchedTransactions.length > 0 ?
                    <TableRowsIcon className="icon sum-icon-excel" onClick={generateTransactionListExcel}
                                   data-tooltip-id="search-transaction-dialog-tooltip" data-tooltip-place="top"
                                   data-tooltip-content="Angezeigte Transaktionen exportieren (Excel)"/> : null}

                {detailedSearch ? <div className="search-options-holder">

                    <div>
                        <TextField size="small"
                                   value={fromDate}
                                   onChange={(e) => setFromDate(e.target.value)} type="date" className='field'
                                   InputLabelProps={{shrink: true}}
                                   label='Von'/>
                        <TextField size="small"
                                   value={toDate}
                                   onChange={(e) => setToDate(e.target.value)} type="date" className='field'
                                   InputLabelProps={{shrink: true}}
                                   label='Bis'/>
                    </div>
                    <Autocomplete
                        size="small"
                        value={searchArticle && searchArticle.nr ? searchArticle.nr : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                searchArticleOnChange({
                                    nr: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                searchArticleOnChange({
                                    nr: newValue.inputValue
                                }, true);
                            } else {
                                searchArticleOnChange(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            return filter(options, params).slice(0, 20);
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        className='field'
                        options={props.articles}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.nr ? option.name + " (" + option.nr + ")" : option.name;
                        }}
                        renderOption={(props, option) => <li
                            key={option.name} {...props}>{option.name + " (" + option.nr + ")"}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 255}}
                                       InputLabelProps={{shrink: true}}
                                       label="Artikel"
                                       value={searchArticle && searchArticle.name ? searchArticle.name : ''}/>
                        )}
                    />
                    <Autocomplete
                        size="small"
                        value={searchBatch && searchBatch.nr ? searchBatch.nr : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                searchBatchOnChange({
                                    nr: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                searchBatchOnChange({
                                    nr: newValue.inputValue
                                }, true);
                            } else {
                                searchBatchOnChange(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            return filter(options, params).slice(0, 20);
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        className='field'
                        options={props.batches}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.nr;
                        }}
                        renderOption={(props, option) => <li
                            key={option.nr} {...props}>{option.nr}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 255}}
                                       InputLabelProps={{shrink: true}}
                                       label="Charge" value={searchBatch && searchBatch.nr ? searchBatch.nr : ''}/>
                        )}
                    />
                    <Autocomplete
                        disabled={searchSupplier}
                        size="small"
                        value={searchCustomer && searchCustomer.name ? searchCustomer.name : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                searchCustomerOnChange({
                                    name: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                searchCustomerOnChange({
                                    name: newValue.inputValue
                                }, true);
                            } else {
                                searchCustomerOnChange(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            return filter(options, params).slice(0, 20);
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        className='field'
                        options={props.customers}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li
                            key={option.name} {...props}>{option.name}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 255}}
                                       InputLabelProps={{shrink: true}}
                                       label="Kunde"
                                       value={searchCustomer && searchCustomer.name ? searchCustomer.name : ''}/>
                        )}
                    />
                    <Autocomplete
                        disabled={searchCustomer}
                        size="small"
                        value={searchSupplier && searchSupplier.name ? searchSupplier.name : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                searchSupplierOnChange({
                                    name: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                searchSupplierOnChange({
                                    name: newValue.inputValue
                                }, true);
                            } else {
                                searchSupplierOnChange(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            return filter(options, params).slice(0, 20);
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        className='field'
                        options={props.suppliers}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li
                            key={option.name} {...props}>{option.name}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 255}}
                                       InputLabelProps={{shrink: true}}
                                       label="Lieferant"
                                       value={searchSupplier && searchSupplier.name ? searchSupplier.name : ''}/>
                        )}
                    />
                </div> : <div className="search-options-holder">
                    <TextField size="small"
                               value={searchWord}
                               onChange={(e) => setSearchWord(e.target.value)} className='field'
                               InputLabelProps={{shrink: true}}
                               label='Suchwort'/>
                </div>}

                <div className="searched-transactions">
                    {searchedTransactions ? searchedTransactions.slice().reverse().map((transaction, index) => (
                        <TransactionItem
                            key={transaction.id}
                            index={index}
                            transaction={transaction}
                            showTransactionDialog={props.showTransactionDialog}
                            showDeleteTransactionDialog={showDeleteTransactionDialog}
                        />
                    )) : null}
                </div>
                <DialogActions>
                    <ToolButton main className='button' onClick={searchTransactionsOnClickHandler}>
                        Suche
                    </ToolButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default SearchTransactionDialog;