import React, {Component} from "react";
import './Tool.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from "../../api";

//Components
import ModuleOverview from "./ModuleOverview/ModuleOverview";
import MenuItem from "@mui/material/MenuItem";
import '../../fonts/OpenSans-Regular-normal';
import '../../fonts/OpenSans-Bold-normal';
import {getAsset} from "../../reusable/PDFEditorDialog/PDFActionsComponent/utils/prepareAssets";
import {readAsArrayBuffer} from "../../reusable/PDFEditorDialog/PDFActionsComponent/utils/asyncReader";

class Tool extends Component {

    state = {
        articleInformation:null,
        batchInformation:null,
        company:null,
        user:null,
        customerOrders:null,
        supplierOrders:null,
        locations:null,
        articles:null,
        customers:null,
        suppliers:null,
        attributes:null,
        partsLists:null,
        reservations:null,
        accountPlan:null,
        articleGroups:null,
        users:null,
        transactions:null,
        accounts:[],
        analyzes:[],
        sentEmails:null,
        rootDirectory:null,
        selectedTransaction:null,
        emailReferencedOrder:null,
        emailAttachments:[],
        breedingSystems:null,
        filters:null,
        messageTemplates:null,
        transaction:null,
        batches:null,

        //Dialogs
        showAddArticleDialog:false,
        showEditArticleDialog:false,
        showUndoTransactionDialog:false,
        showClearHistoryDialog:false
    };

    //Load data

    componentDidMount() {
        this.loadUser();
        this.loadCompany();
        this.loadArticleGroups();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.accountPlan !== this.state.accountPlan) {
            let accounts = this.state.accountPlan ? this.state.accountPlan.accountGroups ? this.getLeafNodes(this.state.accountPlan.accountGroups) : null : null;
            this.setState({accounts:accounts});
        }
    }

    loadData = (loadRequest) => {
        const url = '/load-data';
        api.post(url,loadRequest)
            .then(response => {
                if(response.data.user !== null) {
                    this.setState({user:response.data.user});
                    console.log("[Tool.loadData()]: Benutzer geladen");
                }
                if(response.data.accountPlan !== null) {
                    this.setState({accountPlan:response.data.accountPlan});
                    console.log("[Tool.loadData()]: Accountplan geladen");
                }
                if(response.data.articleGroups !== null) {
                    this.setState({articleGroups:response.data.articleGroups});
                    console.log("[Tool.loadData()]: Artikelgruppen geladen");
                }
                if(response.data.articles !== null) {
                    this.setState({articles:response.data.articles});
                    console.log("[Tool.loadData()]: Artikel geladen");
                }
                if(response.data.attributes !== null) {
                    this.setState({attributes:response.data.attributes});
                    console.log("[Tool.loadData()]: Attribute geladen");
                }
                if(response.data.company !== null) {
                    this.setState({company:response.data.company});
                    console.log("[Tool.loadData()]: Unternehmen geladen");
                }
                if(response.data.customerOrders !== null) {
                    this.setState({customerOrders:response.data.customerOrders});
                    console.log("[Tool.loadData()]: Kundenbestellungen geladen");
                }
                if(response.data.customers !== null) {
                    this.setState({customers:response.data.customers});
                    console.log("[Tool.loadData()]: Kunden geladen");
                }
                if(response.data.locations !== null) {
                    this.setState({locations:response.data.locations});
                    console.log("[Tool.loadData()]: Standorte geladen");
                }
                if(response.data.partsLists !== null) {
                    this.setState({partsLists:response.data.partsLists});
                    console.log("[Tool.loadData()]: Stücklisten geladen");
                }
                if(response.data.sentEmails !== null) {
                    this.setState({sentEmails:response.data.sentEmails});
                    console.log("[Tool.loadData()]: Versandte E-Mails geladen");
                }
                if(response.data.reservations !== null) {
                    this.setState({reservations:response.data.reservations});
                    console.log("[Tool.loadData()]: Reservierungen geladen");
                }
                if(response.data.supplierOrders !== null) {
                    this.setState({supplierOrders:response.data.supplierOrders});
                    console.log("[Tool.loadData()]: Lieferantenbestellungen geladen");
                }
                if(response.data.suppliers !== null) {
                    this.setState({suppliers:response.data.suppliers});
                    console.log("[Tool.loadData()]: Lieferanten geladen");
                }
                if(response.data.users !== null) {
                    this.setState({users:response.data.users});
                    console.log("[Tool.loadData()]: Benutzer geladen");
                }
                if(response.data.rootDirectory !== null) {
                    this.setState({rootDirectory:response.data.rootDirectory});
                    console.log("[Tool.loadData()]: Ordner geladen");
                }
                if(response.data.filters !== null) {
                    this.setState({filters:response.data.filters});
                    console.log("[Tool.loadData()]: Filter geladen");
                }
                if(response.data.messageTemplates !== null) {
                    this.setState({messageTemplates:response.data.messageTemplates});
                    console.log("[Tool.loadData()]: Nachrichtenvorlagen geladen");
                }
                if(response.data.transactions !== null) {
                    this.setState({transactions:response.data.transactions});
                    console.log("[Tool.loadData()]: Transaktionen geladen");
                }
                if(response.data.batches !== null) {
                    this.setState({batches:response.data.batches});
                    console.log("[Tool.loadData()]: Chargen geladen");
                }
            })
            .catch(error => {
                this.props.logout();
            });
    }

    mailTo = (mailInformation) => {
        let mailToLink = `mailto:`;
        if(mailInformation.mailAdress && mailInformation.mailAdress.mail) {
            mailToLink = mailToLink + mailInformation.mailAdress.mail;
        }
        mailToLink = mailToLink + "?";
        if(mailInformation.subject) {
            mailToLink = mailToLink + "subject=" + encodeURIComponent(mailInformation.subject);
        }
        if(mailInformation.body) {
            mailToLink = mailToLink + "&body=" + encodeURIComponent(mailInformation.body);
        }
        window.location.href = mailToLink;
    }

    getLeafNodes = (nodes, result = []) => {
        for(let i = 0; i < nodes.length; i++){
            if(nodes[i].accounts.length !== 0){
                for(let j = 0; j < nodes[i].accounts.length; j++) {
                    result.push(nodes[i].accounts[j]);
                }
            }else{
                result = this.getLeafNodes(nodes[i].accountGroups, result);
            }
        }
        return result;
    }

    addEmailAttachment = (fileStorageLocation,sendWarning) => {
        let emailAttachments = this.state.emailAttachments;
        fileStorageLocation.sendWarning = sendWarning;
        emailAttachments.push(fileStorageLocation);
        this.setState({emailAttachments:emailAttachments});
        this.props.showMessage(0,"Anhang kann nun über das E-Mail-Symbol im rechten Panel versendet werden");
    }

    removeEmailAttachment = (emailAttachment) => {
        let emailAttachments = [...this.state.emailAttachments];
        for(let i = 0; i < emailAttachments.length; i++) {
            if (emailAttachments[i].id === emailAttachment.id) {
                emailAttachments.splice(i,1)
                this.setState({emailAttachments:emailAttachments});
                return;
            }
        }
    }

    clearEmailAttachments = () => {
        this.setState({emailAttachments:[]});
    }

    setEmailReferencedOrder = (order) => {
        this.setState({emailReferencedOrder:order});
    }

    //Loading

    loadCompany = () => {
        const url = '/company';
        api.get(url)
            .then(response => {
                this.setState({company:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadTransactions = () => {
        const url = '/transactions/20';
        api.get(url)
            .then(response => {
                this.setState({transactions:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadArticleGroups = () => {
        const url = '/article-groups';
        api.get(url)
            .then(response => {
                this.setState({articleGroups:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadCustomerOrders = () => {
        const url = '/customer-orders';
        api.get(url)
            .then(response => {
                this.setState({customerOrders:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadBreedingSystems = () => {
        const url = '/breeding-systems';
        api.get(url)
            .then(response => {
                this.setState({breedingSystems:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadSentEmails = () => {
        const url = '/sent-emails';
        api.get(url)
            .then(response => {
                this.setState({sentEmails:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadAccountPlan = () => {
        const url = '/account-plan';
        api.get(url)
            .then(response => {
                this.setState({accountPlan:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadRootDirectory = () => {
        const url = '/directories';
        api.get(url)
            .then(response => {
                this.setState({rootDirectory:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadDeliveryBillsDirectoryStructure = () => {
        const url = '/directory-structure/delivery-bills';
        api.get(url)
            .then(response => {
                this.setState({deliveryBillsDirectoryStructure:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadFactoryCertificatesDirectoryStructure = () => {
        const url = '/directory-structure/factory-certificates';
        api.get(url)
            .then(response => {
                this.setState({factoryCertificatesDirectoryStructure:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadOtherDirectoryStructure = () => {
        const url = '/directory-structure/other';
        api.get(url)
            .then(response => {
                this.setState({otherDirectoryStructure:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadSupplierOrders = () => {
        const url = '/supplier-orders';
        api.get(url)
            .then(response => {
                this.setState({supplierOrders:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadLocations = () => {
        const url = '/locations';
        api.get(url)
            .then(response => {
                this.setState({locations:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadArticles = () => {
        const url = '/articles';
        api.get(url)
            .then(response => {
                this.setState({articles:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadMessageTemplates = () => {
        const url = '/message-templates';
        api.get(url)
            .then(response => {
                this.setState({messageTemplates:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadAttributes = () => {
        const url = '/attributes';
        api.get(url)
            .then(response => {
                this.setState({attributes:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadCustomers = () => {
        const url = '/customers';
        api.get(url)
            .then(response => {
                this.setState({customers:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    };

    loadSuppliers = () => {
        const url = '/suppliers';
        api.get(url)
            .then(response => {
                this.setState({suppliers:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadPartsLists = () => {
        const url = '/parts-lists';
        api.get(url)
            .then(response => {
                this.setState({partsLists:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadReservations = () => {
        const url = '/reservations';
        api.get(url)
            .then(response => {
                this.setState({reservations:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadUser = () => {
        const url = '/users/current';
        api.get(url)
            .then(response => {
                this.setState({user:response.data});
            })
            .catch(error => {
                console.log(error)
                this.props.logout();
            });
    }

    loadUsers = () => {
        const url = '/users';
        api.get(url)
            .then(response => {
                this.setState({users:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    //List changes

    //Reservations
    addReservationToList = (reservation) => {
        this.setState(prevState => ({
            reservations: [reservation, ...prevState.reservations]
        }));
    }

    removeReservationFromList = (reservation) => {
        let reservations = [...this.state.reservations];
        for(let i = 0; i < reservations.length; i++) {
            if (reservations[i].id === reservation.id) {
                reservations.splice(i,1)
                this.setState({reservations:reservations});
                return;
            }
        }
    }

    //Sent Emails
    addSentEmailToList = (sentEmail) => {
        this.setState(prevState => ({
            reservations: [sentEmail, ...prevState.sentEmails]
        }));
    }

    removeSentEmailFromList = (sentEmail) => {
        let sentEmails = [...this.state.sentEmails];
        for(let i = 0; i < sentEmails.length; i++) {
            if (sentEmails[i].id === sentEmail.id) {
                sentEmails.splice(i,1)
                this.setState({sentEmails:sentEmails});
                return;
            }
        }
    }

    //PartsLists
    addPartsListToList = (partsList) => {
        this.setState(prevState => ({
            partsLists: [partsList, ...prevState.partsLists]
        }));
    }

    removePartsListFromList = (partsList) => {
        let partsLists = [...this.state.partsLists];
        for(let i = 0; i < partsLists.length; i++) {
            if(partsLists[i].id === partsList.id) {
                partsLists.splice(i,1);
                this.setState({partsLists:partsLists});
                return;
            }
        }
    }

    //Transaction actions

    clearTransactions = () => {
        this.setState({transactions:[]});
    };

    undoSelectedTransaction = () => {
        if(this.state.selectedTransaction !== null) {
            api.delete("/transactions/" + this.state.selectedTransaction.id)
                .then(response => {
                    this.removeTransactionFromHistory();
                    this.props.showMessage(0,"Transaktion erfolgreich gelöscht!");
                })
                .catch(error => {
                    console.log(error);
                    if(error.response && error.response.data) {
                        this.props.showMessage(2,error.response.data);
                    } else if(!error.response && error.request) {
                        this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    } else {
                        this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    }
                });
        }
    };

    removeTransactionFromHistory = (transaction) => {
        let transactions = [...this.state.transactions];
        for(let i = 0; i < transactions.length; i++) {
            if (transactions[i].id === transaction.id) {
                transactions.splice(i,1)
                this.setState({transactions:transactions});
                return;
            }
        }
    }

    setSelectedTransaction = (selectedTransaction) => {
        let transactions = [...this.state.transactions];
        for(let i = 0; i < transactions.length; i++) {
            if(transactions[i].id === selectedTransaction.id) {
                transactions[i].active = true;
            }else{
                transactions[i].active = false;
            }
        }
        this.setState({selectedTransaction:selectedTransaction,transactions:transactions});
    };

    addTransactionToHistory = (transaction) => {
        let newTransaction = transaction;
        newTransaction.active = false;
        this.setState(prevState => ({
            transactions: [newTransaction, ...prevState.transactions]
        }))
    };

    //Customer Order actions

    addCustomerOrderToList = (customerOrder) => {
        this.setState(prevState => ({
            customerOrders: [customerOrder, ...prevState.customerOrders]
        }));
    }

    removeCustomerOrderFromList = (customerOrder) => {
        let customerOrders = [...this.state.customerOrders];
        for(let i = 0; i < customerOrders.length; i++) {
            if (customerOrders[i].id === customerOrder.id) {
                customerOrders.splice(i,1)
                this.setState({customerOrders:customerOrders});
                return;
            }
        }
    }

    //Supplier Order actions

    addSupplierOrderToList = (supplierOrder) => {
        this.setState(prevState => ({
            supplierOrders: [supplierOrder, ...prevState.supplierOrders]
        }));
    }

    removeSupplierOrderFromList = (supplierOrder) => {
        let supplierOrders = [...this.state.supplierOrders];
        for(let i = 0; i < supplierOrders.length; i++) {
            if (supplierOrders[i].id === supplierOrder.id) {
                supplierOrders.splice(i,1)
                this.setState({supplierOrders:supplierOrders});
                return;
            }
        }
    }

    //Breeding System actions

    addBreedingSystemToList = (breedingSystem) => {
        this.setState(prevState => ({
            breedingSystems: [breedingSystem, ...prevState.breedingSystems]
        }));
    }

    removeBreedingSystemFromList = (breedingSystem) => {
        let breedingSystems = [...this.state.breedingSystems];
        for(let i = 0; i < breedingSystems.length; i++) {
            if (breedingSystems[i].id === breedingSystem.id) {
                breedingSystems.splice(i,1)
                this.setState({breedingSystems:breedingSystems});
                return;
            }
        }
    }

    //Article actions
    addNewArticle = (article) => {
        const url = '/articles';
        api.post(url,article)
            .then(response => {
                this.addArticleToList(response.data);
                this.props.showMessage(0,'Artikel erfolgreich hinzugefügt');
                return true;
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addMultipleArticles = (articles) => {
        this.props.setLoading(true);
        let formattedArticles = [];
        for(let i = 0; i < articles.length; i++) {
            let articleWithArticleGroup = {
                article: {
                    id:articles[i].id,
                    nr:articles[i].nr,
                    ean:articles[i].ean,
                    pcn:articles[i].pcn,
                    refNr:articles[i].refNr,
                    name:articles[i].name,
                    vat:parseFloat(articles[i].vat),
                    purchaseNetPrice:parseFloat(articles[i].purchaseNetPrice),
                    salesNetPrice:parseFloat(articles[i].salesNetPrice),

                    count:parseInt(articles[i].count),
                    dimensions:articles[i].dimensions,
                    weight:parseFloat(articles[i].weight)
                },
                articleGroupName: articles[i].articleGroupName,
                defaultSupplierName: articles[i].defaultSupplierName
            }
            formattedArticles.push(articleWithArticleGroup);
        }
        console.log(formattedArticles)
        const url = '/articles/multiple';
        api.post(url,formattedArticles)
            .then(response => {
                this.setState({articles:response.data.articles,articleGroups:response.data.articleGroups,suppliers:response.data.suppliers});
                this.props.showMessage(0,'Die Artikel mit den jeweils zugehörigen Artikelgruppen wurden erfolgreich angelegt. Artikel mit gedoppelten Artikelnummern wurden nicht hinzugefügt');
                this.props.setLoading(false);
                return true;
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    updateMinimumStock = (articles) => {
        this.props.setLoading(true);
        let articlesWithMinimumStock = articles.map(article => ({
            id: article.id,
            minimumStock: article.minimumStock
        }));
        const url = '/articles/multiple/minimum-stock';
        api.post(url, articlesWithMinimumStock)
            .then(response => {
                this.setState({ articles: response.data.articles });
                this.props.showMessage(0, 'Die Mindestbestände der Artikel wurden aktualisiert');
                this.props.setLoading(false);
                return true;
            })
            .catch(error => {
                console.log(error);
                if (error.response && error.response.data) {
                    this.props.showMessage(2, error.response.data);
                } else if (!error.response && error.request) {
                    this.props.showMessage(2, "Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2, "Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    };

    updateArticle = (article) => {
        const url = '/articles';
        api.put(url,article)
            .then(response => {
                this.updateArticleInList(response.data);
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    deleteArticle = (article) => {
        api.delete("/articles/" + article.id)
            .then(response => {
                this.removeArticleFromList(article);
                this.props.showMessage(0,"Artikel erfolgreich gelöscht");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addArticleToList = (article) => {
        let articles = this.state.articles;
        articles.push(article);
        this.setState({articles:articles});
    };

    updateArticleInList = (article) => {
        let articles = this.state.articles;
        for(let i = 0; i < articles.length; i++) {
            if(articles[i].id === article.id) {
                articles[i] = article;
                break;
            }
        }
        this.setState({articles:articles});
    }

    removeArticleFromList = (article) => {
        let articles = [...this.state.articles];
        for(let i = 0; i < articles.length; i++) {
            if (articles[i].id === article.id) {
                articles.splice(i,1)
                this.setState({articles:articles});
                return;
            }
        }
    }

    displayArticleInformation = (articleInformation) => {
        this.setState({articleInformation:articleInformation});
    };

    getArticleInformation = (article) => {
        if(article && article.id) {
            const url = '/articles/information/' + article.id;
            api.get(url)
                .then(response => {
                    this.displayArticleInformation(response.data);
                })
                .catch(error => {

                });
        }else{
            this.displayArticleInformation(null);
        }
    };

    //Message Template actions
    addNewMessageTemplate = (messageTemplate) => {
        const url = '/message-templates';
        api.post(url,messageTemplate)
            .then(response => {
                this.addMessageTemplateToList(response.data);
                this.props.showMessage(0,'Nachrichtenvorlage erfolgreich hinzugefügt');
                return true;
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    updateMessageTemplate = (messageTemplate) => {
        const url = '/message-templates';
        api.put(url,messageTemplate)
            .then(response => {
                this.updateMessageTemplateInList(response.data);
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    deleteMessageTemplate = (messageTemplate) => {
        api.delete("/message-templates/" + messageTemplate.id)
            .then(response => {
                this.removeMessageTemplateFromList(messageTemplate);
                this.props.showMessage(0,"Nachrichtenvorlage erfolgreich gelöscht");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addMessageTemplateToList = (messageTemplate) => {
        let messageTemplates = this.state.messageTemplates;
        messageTemplates.push(messageTemplate);
        this.setState({messageTemplates:messageTemplates});
    };

    updateMessageTemplateInList = (messageTemplate) => {
        let messageTemplates = this.state.messageTemplates;
        for(let i = 0; i < messageTemplates.length; i++) {
            if(messageTemplates[i].id === messageTemplate.id) {
                messageTemplates[i] = messageTemplate;
                break;
            }
        }
        this.setState({messageTemplates:messageTemplates});
    }

    removeMessageTemplateFromList = (messageTemplate) => {
        let messageTemplates = [...this.state.messageTemplates];
        for(let i = 0; i < messageTemplates.length; i++) {
            if (messageTemplates[i].id === messageTemplate.id) {
                messageTemplates.splice(i,1)
                this.setState({messageTemplates:messageTemplates});
                return;
            }
        }
    }

    //Article Group actions
    addNewArticleGroup = (articleGroup) => {
        console.log(articleGroup)
        const url = '/article-groups';
        api.post(url,articleGroup)
            .then(response => {
                this.addArticleGroupToList(response.data);
                this.props.showMessage(0,'Artikelgruppe erfolgreich hinzugefügt');
                return true;
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    updateArticleGroup = (articleGroup) => {
        const url = '/article-groups';
        api.put(url,articleGroup)
            .then(response => {
                this.updateArticleGroupInList(response.data);
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    deleteArticleGroup = (articleGroup) => {
        api.delete("/article-groups/" + articleGroup.id)
            .then(response => {
                this.props.showMessage(0,"Artikelgruppe erfolgreich gelöscht");
                this.removeArticleGroupFromList(articleGroup);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addArticleGroupToList = (articleGroup) => {
        let articleGroups = this.state.articleGroups;
        articleGroups.push(articleGroup);
        this.setState({articleGroups:articleGroups});
    }

    updateArticleGroupInList = (articleGroup) => {
        let articleGroups = this.state.articleGroups;
        for(let i = 0; i < articleGroups.length; i++) {
            if(articleGroups[i].id === articleGroup.id) {
                articleGroups[i] = articleGroup;
                break;
            }
        }
        this.setState({articleGroups:articleGroups});
    }

    removeArticleGroupFromList = (articleGroup) => {
        let articleGroups = [...this.state.articleGroups];
        for(let i = 0; i < articleGroups.length; i++) {
            if (articleGroups[i].id === articleGroup.id) {
                articleGroups.splice(i,1)
                this.setState({articleGroups:articleGroups});
                return;
            }
        }
    }

    //Location actions
    addNewLocation = (location) => {
        const url = '/locations';
        api.post(url,location)
            .then(response => {
                this.addLocationToList(response.data);
                this.props.showMessage(0,"Standort erfolgreich angelegt");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addMultipleLocations = (locations) => {
        const url = '/locations/multiple';
        api.post(url,locations)
            .then(response => {
                response.data.forEach(location => {
                    this.addLocationToList(location);
                })
                this.props.showMessage(0,'Die Standorte wurden erfolgreich angelegt. Standorte mit gedoppelten Standortnummern wurden nicht hinzugefügt');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    updateLocation = (location) => {
        const url = '/locations';
        api.put(url,location)
            .then(response => {
                this.updateLocationInList(response.data);
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    deleteLocation = (location) => {
        console.log(location);
        api.delete("/locations/" + location.id)
            .then(response => {
                this.props.showMessage(0,"Standort erfolgreich gelöscht");
                this.removeLocationFromList(location.id);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addLocationToList = (location) => {
        let locations = this.state.locations;
        locations.push(location);
        this.setState({locations:locations});
    }

    updateLocationInList = (location) => {
        let locations = this.state.locations;
        for(let i = 0; i < locations.length; i++) {
            if(locations[i].id === location.id) {
                locations[i] = location;
                break;
            }
        }
        this.setState({locations:locations});
    }

    removeLocationFromList = (id) => {
        let locations = [...this.state.locations];
        for(let i = 0; i < locations.length; i++) {
            if (locations[i].id === id) {
                locations.splice(i,1)
                this.setState({locations:locations});
                return;
            }
        }
    }

    //Customer actions
    addNewCustomer = (customer) => {
        const url = '/customers';
        api.post(url,customer)
            .then(response => {
                this.addCustomerToList(response.data);
                this.props.showMessage(0,'Kunde erfolgreich angelegt')
            })

    }

    addMultipleCustomers = (customers) => {
        const url = '/customers/multiple';
        api.post(url,customers)
            .then(response => {
                response.data.forEach(customer => {
                    this.addCustomerToList(customer);
                })
                this.props.showMessage(0,'Die Kunden wurden erfolgreich angelegt. Kunden mit gedoppelten Kundennummern wurden nicht hinzugefügt');
                return true;
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    updateCustomer = (customer) => {
        const url = '/customers';
        api.put(url,customer)
            .then(response => {
                this.updateCustomerInList(response.data);
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert")
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    deleteCustomer = (customer) => {
        api.delete("/customers/" + customer.id)
            .then(response => {
                this.removeCustomerFromList(customer.id);
                this.props.showMessage(0,"Kunde erfolgreich gelöscht");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addCustomerToList = (customer) => {
        let customers = this.state.customers;
        customers.push(customer);
        this.setState({customers:customers});
    }

    updateCustomerInList = (customer) => {
        let customers = this.state.customers;
        for(let i = 0; i < customers.length; i++) {
            if(customers[i].id === customer.id) {
                customers[i] = customer;
                break;
            }
        }
        this.setState({customers:customers});
    }

    removeCustomerFromList = (id) => {
        let customers = [...this.state.customers];
        for(let i = 0; i < customers.length; i++) {
            if (customers[i].id === id) {
                customers.splice(i,1)
                this.setState({customers:customers});
                return;
            }
        }
    }

    // Batches

    addBatchToList = (batch) => {
        let batches = this.state.batches;
        batches.push(batch);
        this.setState({batches:batches});
    }

    updateBatchInList = (batch) => {
        let batches = this.state.batches;
        for(let i = 0; i < batches.length; i++) {
            if(batches[i].id === batch.id) {
                batches[i] = batch;
                break;
            }
        }
        this.setState({batches:batches});
    }

    removeBatchFromList = (batch) => {
        let batches = [...this.state.batches];
        for(let i = 0; i < batches.length; i++) {
            if (batches[i].id === batch.id) {
                batches.splice(i,1)
                this.setState({batches:batches});
                return;
            }
        }
    }

    displayBatchInformation = (batchInformation) => {
        this.setState({batchInformation:batchInformation});
    };

    //Supplier actions
    addNewSupplier = (supplier) => {
        const url = '/suppliers';
        api.post(url,supplier)
            .then(response => {
                this.addSupplierToList(response.data);
                this.props.showMessage(0,'Lieferanten erfolgreich angelegt')
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addMultipleSuppliers = (suppliers) => {
        const url = '/suppliers/multiple';
        api.post(url,suppliers)
            .then(response => {
                response.data.forEach(supplier => {
                    this.addSupplierToList(supplier);
                })
                this.props.showMessage(0,'Die Lieferanten wurden erfolgreich angelegt. Lieferanten mit gedoppelten Lieferantennummern wurden nicht hinzugefügt');
                return true;
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    updateSupplier = (supplier) => {
        const url = '/suppliers';
        api.put(url,supplier)
            .then(response => {
                this.updateSupplierInList(response.data);
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert")
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    deleteSupplier = (supplier) => {
        api.delete("/suppliers/" + supplier.id)
            .then(response => {
                this.props.showMessage(0,"Lieferant erfolgreich gelöscht");
                this.removeSupplierFromList(supplier.id);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addSupplierToList = (supplier) => {
        let suppliers = this.state.suppliers;
        suppliers.push(supplier);
        this.setState({suppliers:suppliers});
    }

    updateSupplierInList = (supplier) => {
        let suppliers = this.state.suppliers;
        for(let i = 0; i < suppliers.length; i++) {
            if(suppliers[i].id === supplier.id) {
                suppliers[i] = supplier;
                break;
            }
        }
        this.setState({suppliers:suppliers});
    }

    removeSupplierFromList = (id) => {
        let suppliers = [...this.state.suppliers];
        for(let i = 0; i < suppliers.length; i++) {
            if (suppliers[i].id === id) {
                suppliers.splice(i,1)
                this.setState({suppliers:suppliers});
                return;
            }
        }
    }

    addFilterToList = (filter) => {
        let filters = this.state.filters;
        filters.push(filter);
        this.setState({filters:filters});
    }

    removeFilterFromList = (filter) => {
        let filters = [...this.state.filters];
        for(let i = 0; i < filters.length; i++) {
            if (filters[i].id === filter.id) {
                filters.splice(i,1)
                this.setState({filters:filters}, () => console.log("neue filtern: " + this.state.filters));
                return;
            }
        }
    }

    //Attribute actions
    addNewAttribute = (attribute) => {
        const url = '/attributes';
        api.post(url,attribute)
            .then(response => {
                this.addAttributeToList(response.data);
                this.props.showMessage(0,'Attribut erfolgreich angelegt')
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addMultipleAttributes = (attributes) => {
        const url = '/attributes/multiple';
        api.post(url,attributes)
            .then(response => {
                response.data.forEach(attribute => {
                    this.addAttributeToList(attribute);
                })
                this.props.showMessage(0,'Die Attribute wurden erfolgreich angelegt. Attribute mit gedoppelten Namen wurden nicht hinzugefügt');
                return true;
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    updateAttribute = (attribute) => {
        const url = '/attributes';
        api.put(url,attribute)
            .then(response => {
                this.updateAttributeInList(response.data);
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert")
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    deleteAttribute = (attribute) => {
        console.log(attribute)
        api.delete("/attributes/" + attribute.id)
            .then(response => {
                this.removeAttributeFromList(attribute.id);
                this.props.showMessage(0,"Attribut erfolgreich gelöscht");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addAttributeToList = (attribute) => {
        let attributes = this.state.attributes;
        attributes.push(attribute);
        this.setState({attributes:attributes});
    }

    updateAttributeInList = (attribute) => {
        let attributes = this.state.attributes;
        for(let i = 0; i < attributes.length; i++) {
            if(attributes[i].id === attribute.id) {
                attributes[i] = attribute;
                break;
            }
        }
        this.setState({attributes:attributes});
    }

    removeAttributeFromList = (id) => {
        let attributes = [...this.state.attributes];
        for(let i = 0; i < attributes.length; i++) {
            if (attributes[i].id === id) {
                attributes.splice(i,1)
                this.setState({attributes:attributes});
                return;
            }
        }
    }

    //Attribute actions
    addNewUser = (user) => {
        const url = '/users';
        api.post(url,user)
            .then(response => {
                console.log(response.data)
                this.addUserToList(response.data);
                this.props.showMessage(0,'Benutzer erfolgreich angelegt')
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    updateUser = (user) => {
        const url = '/users';
        api.put(url,user)
            .then(response => {
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert")
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    deleteUser = (user) => {
        api.delete("/users/" + user.id)
            .then(response => {
                this.props.showMessage(0,"Benutzer erfolgreich gelöscht");
                this.removeUserFromList(user.id);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
            });
    }

    addUserToList = (user) => {
        let users = this.state.users;
        users.push(user);
        this.setState({users:users});
    }

    removeUserFromList = (id) => {
        let users = [...this.state.users];
        for(let i = 0; i < users.length; i++) {
            if (users[i].id === id) {
                users.splice(i,1)
                this.setState({users:users});
                return;
            }
        }
    }

    //Settings

    changePassword = (masterPassword,user) => {
        let masterUser = {
            username:localStorage.getItem('abbreviation') + '.' + localStorage.getItem('username'),
            password:masterPassword
        }
        const loginUrl = '/login';
        api.post(loginUrl,masterUser)
            .then(response => {
                const url = '/users/change-password';
                api.put(url,user)
                    .then(response => {
                        this.props.showMessage(0,'Passwort erfolgreich geändert');
                    })
                    .catch(error => {
                        console.log(error);
                        if(error.response && error.response.data) {
                            this.props.showMessage(2,error.response.data);
                        } else if(!error.response && error.request) {
                            this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                        } else {
                            this.props.showMessage(2,"Etwas ist schiefgelaufen");
                        }
                    });
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                }
            });
    }

    changeAssignedLocation = (updatedUser,assignedLocation) => {
        let user = updatedUser;
        user.assignedLocation = assignedLocation;
        const url = '/users/change-assigned-location';
        api.put(url,user)
            .then(response => {
                this.props.showMessage(0,'Zugewiesener Standort erfolgreich geändert')
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                }
            });
    }

    changeOwnPassword = (changeOwnPasswordRequest) => {
        const url = '/users/change-own-password';
        api.put(url,changeOwnPasswordRequest)
            .then(response => {
                this.props.showMessage(0,'Passwort erfolgreich geändert');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                }
            });
    }

    updateCompany = (company) => {
        const url = '/company';
        api.put(url,company)
            .then(response => {
                this.props.showMessage(0,'Unternehmensinformationen erfolgreich geändert');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                }
            });
    }

    updateUserInformation = (userInformation) => {
        const url = '/users/information';
        api.put(url,userInformation)
            .then(response => {
                this.props.showMessage(0,'Benutzer Informationen erfolgreich geändert');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                }
            });
    }

    updateMultipleFilesOnList = (updatedFiles) => {
        for(let i = 0; i < updatedFiles.length; i++) {
            this.addFiles(this.state.invoicesDirectoryStructure.directories,updatedFiles[i],[]);
        }
    }

    displayFile = (pathFileRequest) => {
        const url = '/file-storage';
        api.post(url,pathFileRequest,{responseType:'blob'})
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: response.data.type});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                }
            });
    }

    addFiles = (nodes,file, result = []) => {
        for(let i = 0; i < nodes.length; i++){
            if(nodes[i].files.length !== 0){
                for(let j = 0; j < nodes[i].files.length; j++) {
                    if(nodes[i].files[j].id === file.id) {
                        nodes[i].files[j] = file;
                        break;
                    }
                }
            }else{
                result = this.addFiles(nodes[i].directories,file,result);
            }
        }
        return result;
    }

    //Account Plan

    saveAccountPlan = (accountPlan) => {
        const url = '/account-plan';
        api.put(url,accountPlan)
            .then(response => {
                this.setState({accountPlan:response.data});
                this.props.showMessage(0,'Änderungen erfolgreich gespeichert');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                }
            });
    }

    updateMultipleAccountsOnList = (updatedAccounts) => {
        for(let i = 0; i < updatedAccounts.length; i++) {
            this.addAccountEntry(this.state.accountPlan.accountGroups,updatedAccounts[i],[]);
        }
    }

    addAccountEntry = (nodes,account, result = []) => {
        for(let i = 0; i < nodes.length; i++){
            if(nodes[i].accounts.length !== 0){
                for(let j = 0; j < nodes[i].accounts.length; j++) {
                    if(nodes[i].accounts[j].id === account.id) {
                        nodes[i].accounts[j] = account;
                        break;
                    }
                }
            }else{
                result = this.addAccountEntry(nodes[i].accountGroups,account,result);
            }
        }
        return result;
    }

    bookCustomerOrderInventoryTransactions = (customerOrder) => {
        const url = '/customer-orders/book-inventory-transactions';
        api.post(url,customerOrder)
            .then(response => {
                let transactions = response.data;
                for(let i = 0; i < transactions.length; i++) {
                    this.addTransactionToHistory(transactions[i]);
                }
                this.props.showMessage(0,'Transaktionen erfolgreich verbucht');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                }
            });
    }

    bookSupplierOrderInventoryTransactions = (supplierOrder) => {
        const url = '/supplier-orders/book-inventory-transactions';
        api.post(url,supplierOrder)
            .then(response => {
                let transactions = response.data;
                for(let i = 0; i < transactions.length; i++) {
                    this.addTransactionToHistory(transactions[i]);
                }
                this.props.showMessage(0,'Transaktionen erfolgreich verbucht');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                }
            });
    }

    round = (value, decimals) => {
        return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    }

    formatString = (string,maxLength) => {
        let substring = string;
        if(string.length > maxLength) {
            substring = string.substring(0,maxLength) + "..."
        }
        return substring;
    }

    formatDate = (date) => {
        return date.substring(8, 10) + "." + date.substring(5,7) + "." + date.substring(0, 4);
    }

    generateDHLShippingLabel = () => {
        this.props.showMessage(1,"In Kürze verfügbar");
    }

    generateDPDShippingLabel = () => {
        this.props.showMessage(1,"In Kürze verfügbar");
    }

    updateDefaultCustomerOrderFilter = (filter) => {
        let user = this.state.user;
        user.userInformation.defaultCustomerOrderFilter = filter;
        this.setState({filter:filter});
    }

    updateDefaultSupplierOrderFilter = (filter) => {
        let user = this.state.user;
        user.userInformation.defaultSupplierOrderFilter = filter;
        this.setState({filter:filter});
    }

    downloadFile = (fileStorageLocation) => {
        this.props.setLoading(true);
        const url = '/file-storage';
        api.post(url,fileStorageLocation,{responseType:'blob'})
            .then(response => {
                const pdfFile = new Blob([response.data], { type: response.data.type });
                const pdfFileURL = URL.createObjectURL(pdfFile);
                const downloadLink = document.createElement('a');
                downloadLink.href = pdfFileURL;
                downloadLink.download = fileStorageLocation.name || 'download.pdf'; // Set default name if fileStorageLocation.name is not available
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(pdfFileURL); // Clean up the URL object
                this.props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                }
                this.props.setLoading(false);
            });
    }

    render() {

        let countries = [
            <MenuItem value='Deutschland'>Deutschland</MenuItem>,<MenuItem value='USA'>USA</MenuItem>,<MenuItem value='Frankreich'>Frankreich</MenuItem>,<MenuItem value='England'>England</MenuItem>,<MenuItem value='Niederlande'>Niederlande</MenuItem>,
            <MenuItem value='China'>China</MenuItem>,<MenuItem value='Österreich'>Österreich</MenuItem>,<MenuItem value='italien'>Italien</MenuItem>,<MenuItem value='Polen'>Polen</MenuItem>,<MenuItem value='Schweiz'>Schweiz</MenuItem>,
            <MenuItem value='Belgien'>Belgien</MenuItem>,<MenuItem value='Spanien'>Spanien</MenuItem>,<MenuItem value='Tschechien'>Tschechien</MenuItem>,<MenuItem value='Schweden'>Schweden</MenuItem>,<MenuItem value='Türkei'>Türkei</MenuItem>,
            <MenuItem value='Ungarn'>Ungarn</MenuItem>,<MenuItem value='---'>---</MenuItem>,

            <MenuItem value='Afghanistan'>Afghanistan</MenuItem>,<MenuItem value='Ägypten'>Ägypten</MenuItem>,<MenuItem value='Albanien'>Albanien</MenuItem>,<MenuItem value='Algerien'>Algerien</MenuItem>,<MenuItem value='Andorra'>Andorra</MenuItem>,
            <MenuItem value='Angola'>Angola</MenuItem>,<MenuItem value='Antigua und Barbuda'>Antigua und Barbuda</MenuItem>,<MenuItem value='Äquatorialguinea'>Äquatorialguinea</MenuItem>,<MenuItem value='Argentinien'>Argentinien</MenuItem>,<MenuItem value='Armenien'>Armenien</MenuItem>,
            <MenuItem value='Aserbaidschan'>Aserbaidschan</MenuItem>,<MenuItem value='Äthiopien'>Äthiopien</MenuItem>,<MenuItem value='Australien'>Australien</MenuItem>,<MenuItem value='Bahamas'>Bahamas</MenuItem>,<MenuItem value='Bahrain'>Bahrain</MenuItem>,
            <MenuItem value='Bangladesch'>Bangladesch</MenuItem>,<MenuItem value='Barbados'>Barbados</MenuItem>,<MenuItem value='Belarus'>Belarus</MenuItem>,<MenuItem value='Belgien'>Belgien</MenuItem>,<MenuItem value='Belize'>Belize</MenuItem>,
            <MenuItem value='Benin'>Benin</MenuItem>,<MenuItem value='Bhutan'>Bhutan</MenuItem>,<MenuItem value='Bolivien'>Bolivien</MenuItem>,<MenuItem value='Bosnien und Herzegowina'>Bosnien und Herzegowina</MenuItem>,<MenuItem value='Botsuana'>Botsuana</MenuItem>,
            <MenuItem value='Brasilien'>Brasilien</MenuItem>,<MenuItem value='Brunei Darussalam'>Brunei Darussalam</MenuItem>,<MenuItem value='Bulgarien'>Bulgarien</MenuItem>,<MenuItem value='Burkina Faso'>Burkina Faso</MenuItem>,<MenuItem value='Burundi'>Burundi</MenuItem>,
            <MenuItem value='Cabo Verde'>Cabo Verde</MenuItem>,<MenuItem value='Chile'>Chile</MenuItem>,<MenuItem value='China'>China</MenuItem>,<MenuItem value='Cookinseln'>Cookinseln</MenuItem>,<MenuItem value='Costa Rica'>Costa Rica</MenuItem>,
            <MenuItem value='Cote d Ivoire'>Cote d Ivoire</MenuItem>,<MenuItem value='Dänemark'>Dänemark</MenuItem>,<MenuItem value='Deutschland'>Deutschland</MenuItem>,<MenuItem value='Dominica'>Dominica</MenuItem>,<MenuItem value='Dominikanische Republik'>Dominikanische Republik</MenuItem>,
            <MenuItem value='Dschibuti'>Dschibuti</MenuItem>,<MenuItem value='Ecuador'>Ecuador</MenuItem>,<MenuItem value='El Salvador'>El Salvador</MenuItem>,<MenuItem value='England'>England</MenuItem>,<MenuItem value='Eritrea'>Eritrea</MenuItem>,
            <MenuItem value='Estland'>Estland</MenuItem>,<MenuItem value='Eswatini'>Eswatini</MenuItem>,<MenuItem value='Fidschi'>Fidschi</MenuItem>,<MenuItem value='Finnland'>Finnland</MenuItem>,<MenuItem value='Frankreich'>Frankreich</MenuItem>,
            <MenuItem value='Gabun'>Gabun</MenuItem>,<MenuItem value='Gambia'>Gambia</MenuItem>,<MenuItem value='Georgien'>Georgien</MenuItem>,<MenuItem value='Ghana'>Ghana</MenuItem>,<MenuItem value='Grenada'>Grenada</MenuItem>,
            <MenuItem value='Griechenland'>Griechenland</MenuItem>,<MenuItem value='Guatemala'>Guatemala</MenuItem>,<MenuItem value='Guinea'>Guinea</MenuItem>,<MenuItem value='Guinea-Bissau'>Guinea-Bissau</MenuItem>,<MenuItem value='Guyana'>Guyana</MenuItem>,
            <MenuItem value='Haiti'>Haiti</MenuItem>,<MenuItem value='Heiliger Stuhl'>Heiliger Stuhl</MenuItem>,<MenuItem value='Honduras'>Honduras</MenuItem>,<MenuItem value='Indien'>Indien</MenuItem>,<MenuItem value='Indonesien'>Indonesien</MenuItem>,
            <MenuItem value='Irak'>Irak</MenuItem>,<MenuItem value='Iran'>Iran</MenuItem>,<MenuItem value='Irland'>Irland</MenuItem>,<MenuItem value='Island'>Island</MenuItem>,<MenuItem value='Israel'>Israel</MenuItem>,
            <MenuItem value='Italien'>Italien</MenuItem>,<MenuItem value='Jamaika'>Jamaika</MenuItem>,<MenuItem value='Japan'>Japan</MenuItem>,<MenuItem value='Jemen'>Jemen</MenuItem>,<MenuItem value='Jordanien'>Jordanien</MenuItem>,
            <MenuItem value='Kambodscha'>Kambodscha</MenuItem>,<MenuItem value='Kamerun'>Kamerun</MenuItem>,<MenuItem value='Kanada'>Kanada</MenuItem>,<MenuItem value='Kasachstan'>Kasachstan</MenuItem>,<MenuItem value='Katar'>Katar</MenuItem>,
            <MenuItem value='Kenia'>Kenia</MenuItem>,<MenuItem value='Kirgisistan'>Kirgisistan</MenuItem>,<MenuItem value='Kiribati'>Kiribati</MenuItem>,<MenuItem value='Kolumbien'>Kolumbien</MenuItem>,<MenuItem value='Komoren'>Komoren</MenuItem>,
            <MenuItem value='Kongo'>Kongo</MenuItem>,<MenuItem value='Korea'>Korea</MenuItem>,<MenuItem value='Kosovo'>Kosovo</MenuItem>,<MenuItem value='Kroatien'>Kroatien</MenuItem>,<MenuItem value='Kuba'>Kuba</MenuItem>,
            <MenuItem value='Kuwait'>Kuwait</MenuItem>,<MenuItem value='Laos'>Laos</MenuItem>,<MenuItem value='Lesotho'>Lesotho</MenuItem>,<MenuItem value='Lettland'>Lettland</MenuItem>,<MenuItem value='Libanon'>Libanon</MenuItem>,
            <MenuItem value='Liberia'>Liberia</MenuItem>,<MenuItem value='Libyen'>Libyen</MenuItem>,<MenuItem value='Liechtenstein'>Liechtenstein</MenuItem>,<MenuItem value='Litauen'>Litauen</MenuItem>,<MenuItem value='Luxemburg'>Luxemburg</MenuItem>,
            <MenuItem value='Madagaskar'>Madagaskar</MenuItem>,<MenuItem value='Malawi'>Malawi</MenuItem>,<MenuItem value='Malaysia'>Malaysia</MenuItem>,<MenuItem value='Malediven'>Malediven</MenuItem>,<MenuItem value='Mali'>Mali</MenuItem>,
            <MenuItem value='Malta'>Malta</MenuItem>,<MenuItem value='Marokko'>Marokko</MenuItem>,<MenuItem value='Marshallinseln'>Marshallinseln</MenuItem>,<MenuItem value='Mauretanien'>Mauretanien</MenuItem>,<MenuItem value='Mexiko'>Mexiko</MenuItem>,
            <MenuItem value='Mikronesien'>Mikronesien</MenuItem>,<MenuItem value='Moldau'>Moldau</MenuItem>,<MenuItem value='Monaco'>Monaco</MenuItem>,<MenuItem value='Mongolei'>Mongolei</MenuItem>,<MenuItem value='Montenegro'>Montenegro</MenuItem>,
            <MenuItem value='Mosambik'>Mosambik</MenuItem>,<MenuItem value='Namibia'>Namibia</MenuItem>,<MenuItem value='Nauru'>Nauru</MenuItem>,<MenuItem value='Nepal'>Nepal</MenuItem>,<MenuItem value='Neuseeland'>Neuseeland</MenuItem>,
            <MenuItem value='Nicaragua'>Nicaragua</MenuItem>,<MenuItem value='Niederlande'>Niederlande</MenuItem>,<MenuItem value='Niger'>Niger</MenuItem>,<MenuItem value='Nigeria'>Nigeria</MenuItem>,<MenuItem value='Niue'>Niue</MenuItem>,
            <MenuItem value='Nordmazedonien'>Nordmazedonien</MenuItem>,<MenuItem value='Norwegen'>Norwegen</MenuItem>,<MenuItem value='Oman'>Oman</MenuItem>,<MenuItem value='Österreich'>Österreich</MenuItem>,<MenuItem value='Pakistan'>Pakistan</MenuItem>,
            <MenuItem value='Palau'>Palau</MenuItem>,<MenuItem value='Panama'>Panama</MenuItem>,<MenuItem value='Papua-Neuguinea'>Papua-Neuguinea</MenuItem>,<MenuItem value='Paraguay'>Paraguay</MenuItem>,<MenuItem value='Peru'>Peru</MenuItem>,
            <MenuItem value='Philippinen'>Philippinen</MenuItem>,<MenuItem value='Polen'>Polen</MenuItem>,<MenuItem value='Portugal'>Portugal</MenuItem>,<MenuItem value='Ruanda'>Ruanda</MenuItem>,<MenuItem value='Rumänien'>Rumänien</MenuItem>,
            <MenuItem value='Russland'>Russland</MenuItem>,<MenuItem value='Salomonen'>Salomonen</MenuItem>,<MenuItem value='Sambia'>Sambia</MenuItem>,<MenuItem value='Samoa'>Samoa</MenuItem>,<MenuItem value='San Marino'>San Marino</MenuItem>,
            <MenuItem value='Sao Tome und Principe'>São Tomé und Príncipe</MenuItem>,<MenuItem value='Saudi-Arabien'>Saudi-Arabien</MenuItem>,<MenuItem value='Schottland'>Schottland</MenuItem>,<MenuItem value='Schweden'>Schweden</MenuItem>,<MenuItem value='Schweiz'>Schweiz</MenuItem>,
            <MenuItem value='Senegal'>Senegal</MenuItem>,<MenuItem value='Serbien'>Serbien</MenuItem>,<MenuItem value='Seychellen'>Seychellen</MenuItem>,<MenuItem value='Sierra Leone'>Sierra Leone</MenuItem>,<MenuItem value='Simbabwe'>Simbabwe</MenuItem>,
            <MenuItem value='Singapur'>Singapur</MenuItem>,<MenuItem value='Slowakei'>Slowakei</MenuItem>,<MenuItem value='Slowenien'>Slowenien</MenuItem>,<MenuItem value='Somalia'>Somalia</MenuItem>,<MenuItem value='Spanien'>Spanien</MenuItem>,
            <MenuItem value='Sri Lanka'>Sri Lanka</MenuItem>,<MenuItem value='St Kitts und Nevis'>St. Kitts und Nevis</MenuItem>,<MenuItem value='St Lucia'>St. Lucia</MenuItem>,<MenuItem value='St Vincent und die Grenadinen'>St. Vincent und die Grenadinen</MenuItem>,<MenuItem value='Südafrika'>Südafrika</MenuItem>,
            <MenuItem value='Sudan'>Sudan</MenuItem>,<MenuItem value='Südsudan'>Südsudan</MenuItem>,<MenuItem value='Suriname'>Suriname</MenuItem>,<MenuItem value='Syrien'>Syrien</MenuItem>,<MenuItem value='Tadschikistan'>Tadschikistan</MenuItem>,
            <MenuItem value='Tansania'>Tansania</MenuItem>,<MenuItem value='Thailand'>Thailand</MenuItem>,<MenuItem value='Timor-Leste'>Timor-Leste</MenuItem>,<MenuItem value='Togo'>Togo</MenuItem>,<MenuItem value='Tonga'>Tonga</MenuItem>,
            <MenuItem value='Trinidad und Tobago'>Trinidad und Tobago</MenuItem>,<MenuItem value='Tschad'>Tschad</MenuItem>,<MenuItem value='Tschechien'>Tschechien</MenuItem>,<MenuItem value='Tunesien'>Tunesien</MenuItem>,<MenuItem value='Türkei'>Türkei</MenuItem>,
            <MenuItem value='Turkmenistan'>Turkmenistan</MenuItem>,<MenuItem value='Tuvalu'>Tuvalu</MenuItem>,<MenuItem value='Uganda'>Uganda</MenuItem>,<MenuItem value='Ukraine'>Ukraine</MenuItem>,<MenuItem value='Ungarn'>Ungarn</MenuItem>,
            <MenuItem value='Uruguay'>Uruguay</MenuItem>,<MenuItem value='USA'>USA</MenuItem>,<MenuItem value='Usbekistan'>Usbekistan</MenuItem>,<MenuItem value='Vanuatu'>Vanuatu</MenuItem>,<MenuItem value='Vatikanstadt'>Vatikanstadt</MenuItem>,
            <MenuItem value='Venezuela'>Venezuela</MenuItem>,<MenuItem value='Vereinigte Arabische Emirate'>Vereinigte Arabische Emirate</MenuItem>,<MenuItem value='Vietnam'>Vietnam</MenuItem>,<MenuItem value='Wales'>Wales</MenuItem>,<MenuItem value='Weißrussland'>Weißrussland</MenuItem>,
            <MenuItem value='Zentralafrikanische Republik'>Zentralafrikanische Republik</MenuItem>,<MenuItem value='Zypern'>Zypern</MenuItem>
        ]

        return (
            <div className='tool'>
                <ModuleOverview
                    //Common
                    loadData={this.loadData}
                    showMessage={this.props.showMessage}
                    analyzes={this.state.analyzes}
                    countries={countries}
                    round={this.round}
                    formatString={this.formatString}
                    formatDate={this.formatDate}
                    generateDocument={this.generateDocument}
                    generateDeliveryBill={this.generateDeliveryBill}
                    generateDPDShippingLabel={this.generateDPDShippingLabel}
                    generateDHLShippingLabel={this.generateDHLShippingLabel}
                    setLoading={this.props.setLoading}
                    mailTo={this.mailTo}
                    downloadFile={this.downloadFile}

                    //User
                    user={this.state.user}
                    changeUsername={this.changeUsername}
                    changePassword={this.changePassword}
                    changeAssignedLocation={this.changeAssignedLocation}
                    changeOwnPassword={this.changeOwnPassword}
                    updateUserInformation={this.updateUserInformation}
                    updateDefaultCustomerOrderFilter={this.updateDefaultCustomerOrderFilter}
                    updateDefaultSupplierOrderFilter={this.updateDefaultSupplierOrderFilter}
                    logout={this.props.logout}

                    //Company
                    company={this.state.company}
                    updateCompany={this.updateCompany}

                    //Articles
                    articleInformation={this.state.articleInformation}
                    displayArticleInformation={this.displayArticleInformation}
                    getArticleInformation={this.getArticleInformation}
                    articles={this.state.articles}
                    loadArticles={this.loadArticles}
                    addNewArticle={this.addNewArticle}
                    addMultipleArticles={this.addMultipleArticles}
                    updateArticle={this.updateArticle}
                    addArticleToList={this.addArticleToList}
                    deleteArticle={this.deleteArticle}
                    updateMinimumStock={this.updateMinimumStock}

                    //MessageTemplates
                    messageTemplates={this.state.messageTemplates}
                    loadMessageTemplates={this.loadMessageTemplates}
                    addNewMessageTemplate={this.addNewMessageTemplate}
                    updateMessageTemplate={this.updateMessageTemplate}
                    addMessageTemplateToList={this.addMessageTemplateToList}
                    deleteMessageTemplate={this.deleteMessageTemplate}

                    //Locations
                    locations={this.state.locations}
                    loadLocations={this.loadLocations}
                    addNewLocation={this.addNewLocation}
                    addMultipleLocations={this.addMultipleLocations}
                    updateLocation={this.updateLocation}
                    deleteLocation={this.deleteLocation}

                    //Customers
                    customers={this.state.customers}
                    loadCustomers={this.loadCustomers}
                    addNewCustomer={this.addNewCustomer}
                    addMultipleCustomers={this.addMultipleCustomers}
                    updateCustomer={this.updateCustomer}
                    deleteCustomer={this.deleteCustomer}
                    addCustomerToList={this.addCustomerToList}

                    //Suppliers
                    suppliers={this.state.suppliers}
                    loadSuppliers={this.loadSuppliers}
                    addNewSupplier={this.addNewSupplier}
                    addMultipleSuppliers={this.addMultipleSuppliers}
                    updateSupplier={this.updateSupplier}
                    deleteSupplier={this.deleteSupplier}
                    addSupplierToList={this.addSupplierToList}

                    //Attributes
                    attributes={this.state.attributes}
                    loadAttributes={this.loadAttributes}
                    addNewAttribute={this.addNewAttribute}
                    addMultipleAttributes={this.addMultipleAttributes}
                    updateAttribute={this.updateAttribute}
                    deleteAttribute={this.deleteAttribute}

                    //Users
                    users={this.state.users}
                    loadUsers={this.loadUsers}
                    addNewUser={this.addNewUser}
                    updateUser={this.updateUser}
                    deleteUser={this.deleteUser}

                    //CustomerOrders
                    customerOrders={this.state.customerOrders}
                    loadCustomerOrders={this.loadCustomerOrders}
                    removeCustomerOrderFromList={this.removeCustomerOrderFromList}
                    addCustomerOrderToList={this.addCustomerOrderToList}

                    //SupplierOrders
                    supplierOrders={this.state.supplierOrders}
                    loadSupplierOrders={this.loadSupplierOrders}
                    addSupplierOrderToList={this.addSupplierOrderToList}
                    removeSupplierOrderFromList={this.removeSupplierOrderFromList}

                    //Filters
                    filters={this.state.filters}
                    addFilterToList={this.addFilterToList}
                    removeFilterFromList={this.removeFilterFromList}

                    //PartsLists
                    partsLists={this.state.partsLists}
                    loadPartsLists={this.loadPartsLists}
                    addPartsListToList={this.addPartsListToList}
                    removePartsListFromList={this.removePartsListFromList}

                    //Reservations
                    reservations={this.state.reservations}
                    loadReservations={this.loadReservations}
                    addReservationToList={this.addReservationToList}
                    removeReservationFromList={this.removeReservationFromList}

                    //Transactions
                    transactions={this.state.transactions}
                    loadTransactions={this.loadTransactions}
                    addTransactionToHistory={this.addTransactionToHistory}
                    setSelectedTransaction={this.setSelectedTransaction}
                    undoSelectedTransaction={this.undoSelectedTransaction}
                    removeTransactionFromHistory={this.removeTransactionFromHistory}
                    clearTransactions={this.clearTransactions}
                    bookCustomerOrderInventoryTransactions={this.bookCustomerOrderInventoryTransactions}
                    bookSupplierOrderInventoryTransactions={this.bookSupplierOrderInventoryTransactions}

                    //Article Groups
                    articleGroups={this.state.articleGroups}
                    loadArticleGroups={this.loadArticleGroups}
                    addNewArticleGroup={this.addNewArticleGroup}
                    updateArticleGroup={this.updateArticleGroup}
                    deleteArticleGroup={this.deleteArticleGroup}

                    //Account Plan
                    accountPlan={this.state.accountPlan}
                    loadAccountPlan={this.loadAccountPlan}
                    saveAccountPlan={this.saveAccountPlan}
                    updateMultipleAccountsOnList={this.updateMultipleAccountsOnList}

                    //Accounts
                    accounts={this.state.accounts}

                    //Directory Structures
                    loadRootDirectory={this.loadRootDirectory}
                    updateMultipleFilesOnList={this.updateMultipleFilesOnList}
                    rootDirectory={this.state.rootDirectory}

                    //Files
                    saveFile={this.saveFile}
                    displayFile={this.displayFile}
                    loadFile={this.loadFile}

                    //Email
                    addEmailAttachment={this.addEmailAttachment}
                    emailAttachments={this.state.emailAttachments}
                    removeEmailAttachment={this.removeEmailAttachment}
                    clearEmailAttachments={this.clearEmailAttachments}
                    emailReferencedOrder={this.state.emailReferencedOrder}
                    setEmailReferencedOrder={this.setEmailReferencedOrder}

                    //Sent Emails
                    loadSentEmails={this.loadSentEmails}
                    sentEmails={this.state.sentEmails}
                    addSentEmailToList={this.addSentEmailToList}
                    removeSentEmailFromList={this.removeSentEmailFromList}

                    //Breeding Systems
                    loadBreedingSystems={this.loadBreedingSystems}
                    breedingSystems={this.state.breedingSystems}
                    addBreedingSystemToList={this.addBreedingSystemToList}
                    removeBreedingSystemFromList={this.removeBreedingSystemFromList}

                    // Batches
                    batches={this.state.batches}
                    addBatchToList={this.addBatchToList}
                    updateBatchInList={this.updateBatchInList}
                    removeBatchFromList={this.removeBatchFromList}
                    batchInformation={this.state.batchInformation}
                    displayBatchInformation={this.displayBatchInformation}
                />
            </div>
        )
    }
}

export default Tool;