import React, { useState, useEffect, useRef } from 'react';
import './TransactionItem.css';
import Paper from "@mui/material/Paper";
import HelperFunctions from "../HelperFunctions";
import DeleteIcon from "@mui/icons-material/Delete";
import ArticleIcon from "@mui/icons-material/Article";

//Component

const TransactionItem = (props) => {

    let articleInfo = "";
    if(props.transaction && props.transaction.article && props.transaction.article.name) {
        articleInfo = props.transaction.article.name;
    }
    if(props.transaction && props.transaction.article && props.transaction.article.nr) {
        articleInfo = articleInfo + " (" + props.transaction.article.nr + ")";
    }

    return (
        <Paper elevation={3} className='transaction-item'
               style={props.transaction.inventoryCount >= 0 ? {background: "#D5EDD5"} : {background: "#F0D8D8"}}>
            <p className="date-time-info">{HelperFunctions.formatDateTime(props.transaction.dateTime)}</p>
            <p className="count-info"
               style={props.transaction.inventoryCount >= 0 ? {color: "#3BED3B"} : {color: "#F03C3C"}}>{props.transaction.inventoryCount}</p>
            <p className="article-info">{articleInfo}</p>
            {props.transaction.assignedCustomer ? <p className="business-partner-info">an {props.transaction.assignedCustomer.name}</p> : null}
            {props.transaction.assignedSupplier ? <p className="business-partner-info">von {props.transaction.assignedSupplier.name}</p> : null}
            <ArticleIcon
                onClick={() => props.showTransactionDialog(props.transaction)}
                className='icon edit-icon'
            />
            {!props.small ? <DeleteIcon
                onClick={() => props.showDeleteTransactionDialog(props.transaction)}
                className='icon delete-icon'
            /> : null}
        </Paper>
    )
}

export default TransactionItem;